.quote {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid var(--dark-grey);
  background-color: var(--off-white);
  height: 100%;
  justify-content: space-between;

  &-main {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }

  footer {
    display: flex;
    flex-direction: column;
  }

  &-profile {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  &-logo {
    height: 24px;
    width: auto;
    // width: fit-content; //breaks in safari
  }

  &-citation {
    display: flex;
    gap: 6px;
    align-items: center;

    @include respond-to('medium') {
      gap: 8px;
    }

    // &:before {
    //   display: inline-block;
    //   content: " ";
    //   width: 8px;
    //   height: 8px;
    //   background-color: var(--green);
    //   border-radius: 50%;
    //   flex-shrink: 0;

    //   @include respond-to('medium') {
    //     width: 12px;
    //     height: 12px;
    //   }
    // }
  }

  &-image {
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
}
