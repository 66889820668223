
.line {
  margin: 0;
  border: 1px solid transparent;
  border-top: 1px solid var(--text-primary);
  width: 100%;

  @include respond-to('medium') {
    margin: 2rem 0;
  }
}
