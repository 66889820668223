.credits {
  width: 100%;
  margin: 2rem 0;

  @media (max-width: 719px) {
    .grid {
      gap: 4rem;
    }

    .column {
      align-items: center;
      text-align: center;
    }

    .contactprofile {
      flex-direction: column;
      align-items: center;
    }
  }
}