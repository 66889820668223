/**
 * Fonts
*/


@font-face {
  font-family: 'New Spirit';
  src: 
  url("./../../assets/fonts/new-spirit/NewSpirit-Regular.otf") format("opentype"),
  url("./../../assets/fonts/new-spirit/NewSpirit-Regular.ttf") format("truetype"),
  url("./../../assets/fonts/new-spirit/NewSpirit-Regular.woff") format("woff"),
  url("./../../assets/fonts/new-spirit/NewSpirit-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Bw Gradual';
  src: 
  url("./../../assets/fonts/bw-gradual/BwGradual-Regular.otf") format("opentype"),
  url("./../../assets/fonts/bw-gradual/BwGradual-Regular.woff") format("woff"),
  url("./../../assets/fonts/bw-gradual/BwGradual-Regular.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}
