.textcard {
  padding: 16px 24px 16px 16px;
  text-align: left;
  border: 1px solid var(--dark-grey);
  border-radius: 20px;
  // min-width: 348px;
  min-width: 284px;
  position: relative;

  @include respond-to('large') {
    min-width: 100%;
    display: flex;
    gap: 10px;
    padding: 0;
    border-color: transparent;
  }

  .textcard-marker {
    background-color: #D0C5FB;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    flex-shrink: 0;
    margin-top: calc(var(--copy-text) / 2);
    display: none;

    @include respond-to('large') {
      display: block;
    }
  }

  svg {
    position: absolute;
    right: 8px;
    bottom: 8px;
    color: var(--dark-grey);

    @include respond-to('large') {
      display: none;
    }
  }
}

.textcard-wrapper {
  display: flex;
  gap: 16px;
  width: 100vw;
  margin-left: -16px;
  padding: 0 16px;
  padding-bottom: 32px;
  overflow-x: scroll;
  overflow-y: hidden;
  // border: 1px solid red;

  @include respond-to('medium') {
    margin-left: -32px;
    padding: 0 32px;
    padding-bottom: 32px;
  }

  @include respond-to('large') {
    padding: 0;
    margin-left: 0;
    width: 100%;
    overflow: hidden;
    flex-direction: column;
  }
}



//remove scroll bar
.textcard-wrapper {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.textcard-wrapper::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
