.awardlist {
  width: 100%;
  display: block;
  padding-left: 0;
  margin-left: 0;

  li {
    border-bottom: 1px solid var(--text-primary);
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 12px;
    
    .awardlogo {
      background-color: var(--surface-primary);
      
      img {
        width: 48px;
        height: 48px;
        mix-blend-mode: multiply; /* Blend mode for the image */
        filter: grayscale(100%); /* Ensuring the image is grayscale */
        object-fit: cover; /* Ensuring the image covers the defined area */
      }
    }

    &:last-of-type {
      border-bottom: none;
    }
  }
}
