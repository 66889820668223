//fyi: some values are overwritten in credits.scss

.contactprofile {
    display: flex;
    gap: 12px;
    width: 100%;
    align-items: center;
    text-align: center;
    // align-items: flex-start;
    flex-direction: column;
    background-color: var(--surface-secondary);
    padding: 1rem;
    border-radius: var(--general-radius);
    
    @container (min-width: 120px) {
        // flex-direction: row;
        // flex-wrap: wrap;
        // align-items: flex-start;
        // gap: 6px;
    }

    @container (min-width: 360px) {
        padding: 2rem;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        text-align: left;
        // align-items: center;
        // gap: 12px;
    }
    

    &-image img {
        width: 96px;
        height: 96px;
        object-fit: cover;
        border-radius: 50%;

        // @container (min-width: 120px) {
        //     width: 46px;
        //     height: 46px;
        // }

        // @container (min-width: 280px) {
        //     width: 96px;
        //     height: 96px;
        // }

        // @container (min-width: 280px) {
        //     width: 128px;
        //     height: 128px;
        // }
        
        // @container (min-width: 380px) {
        //     width: 128px;
        //     height: 128px;
        // }
    }

    &-info {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;

        @container (min-width: 360px) {
            width: fit-content;
        }
    }

    &-buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;

        --text-hover: var(--navigation-active);

        @container (min-width: 360px) {
            align-items: flex-start;
        }
        // justify-content: space-between;

        // @container (min-width: 120px) {
        //     gap: 6px;
        // }

        // @container (min-width: 280px) {
        //     gap: 12px;
        //     // justify-content: flex-start;
        // }
    }
}