/**
 * Helper mixins
*/

// Clearfix
// http://www.cssmojo.com/latest_new_clearfix_so_far/
// Other clearfix options: https://github.com/stubbornella/oocss/blob/master/oocss/src/components/utils/_clearfix-me.scss
@mixin clearfix {
  &::after {
    clear: both;
    content: '';
    display: table;
  }
}

// Media queries
@mixin bp($point) {
  @if $point == large {
    @media (min-width: 1280px) { @content; }
  } @else if $point == small {
    @media (max-width: 1279px) { @content; }
  }
}

// Text selection
@mixin text-selection($color-selection, $color-selection-bg) {
  ::-moz-selection { // sass-lint:disable-line no-vendor-prefixes
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }

  ::selection {
    background: $color-selection-bg;
    color: $color-selection;
    text-shadow: none;
  }
}

//Placeholders
//Usage:
//@include placeholder;
@mixin placeholder {
  // sass-lint:disable no-vendor-prefixes
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}




@mixin respond-to($name) {
  @if not map-has-key($breakpoints, $name) {
    @warn "Warning: `#{$name}` is not a valid breakpoint name.";
  }

  @else {
    @media (min-width: map-get($breakpoints, $name)) {
      @content;
    }
  }
}
