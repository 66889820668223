.people {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 1rem;
  width: 100%;
  gap: 1rem;
  
  @container (min-width: 480px) {
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
  }

  @container (min-width: 660px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @container (min-width: 880px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @container (min-width: 1200px) {
    grid-template-columns: repeat(5, 1fr);
  }

  &-item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    // overflow: clip; //breaks on safari ios
  
    --radius: 10px;
    --transition-transform: transform 500ms cubic-bezier(0.25, 1, 0.5, 1);

    &-wrapper {
      position: relative;
    }

    &-visual {
      display: block;
      position: relative;
      aspect-ratio: 3 / 4;
      width: 100%;
      border-radius: var(--radius);
      overflow: clip;
      background: white;
  
      img {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &-cta {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    grid-column: span 2;
    
    @container (min-width: 440px) {
      grid-column: span 1;
    }

    &-text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}