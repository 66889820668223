.founder-video {
  // border: 1px solid red;
  width: 100%;
  margin: 0 auto;
  position: relative;

  @include respond-to('large') {
    max-width: 820px;
  }

  @include respond-to('xxl') {
    max-width: 1200px;
  }

  &-wrapper {
    position: relative;
    overflow: hidden;

    &:after {
      display: block;
      position: absolute;
      inset: 0;
      top: auto;
      content: " ";
      width: 100%;
      padding-bottom: 56.25%;
      border-radius: 20px;
      z-index: 50;
      background-color: var(--yellow);
      transition: opacity 300ms, transform 300ms;
    }

    &.cover-hidden {
      .founder-video-portrait {
        pointer-events: none;
        transform: translate3d(0, 10%, 0);
        opacity: 0;
      }
      .founder-video-button {
        pointer-events: none;
        opacity: 0;
        bottom: 16px;
      }
      &:after {
        pointer-events: none;
        opacity: 0;
        transform: translate3d(0, -10%, 0);
      }
      .plyr {
        transform: translate3d(0, -10%, 0);
      }
    }
  }

  &-topline {
    text-align: center;
    margin-bottom: 8px;
  }

  &-headline {
    text-align: center;
    margin-bottom: 32px;
  }

  &-portrait {
    position: absolute;
    inset: 0;
    z-index: 100;
    top: auto;
    transition: opacity 300ms, transform 300ms;
  }

  &-text {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;

    &-right {
      text-align: right;
    }
  }

  .plyr {
    margin-top: 12%;
    border-radius: 20px;
    transition: opacity 300ms, transform 300ms;
  }

  &-button.button{
    position: absolute;
    // inset: 0;
    bottom: 48px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    margin: 0 auto;
    z-index: 200;
    transition: opacity 300ms, bottom 300ms;
  }
}
