.jobs {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  .job {
    display: flex;
    gap: 1rem;
    border: 1px solid var(--text-disabled);
    border-radius: var(--general-radius);
    padding: 1rem;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    // flex-wrap: wrap;

    .button {
      width: 100%;
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &-header {
      display: flex;
      gap: 8px;
      align-items: center;
      flex-wrap: wrap;

      svg {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }

      p {
        white-space: nowrap;
      }
    }

    @container (min-width: 649px) {
      flex-direction: row;
      align-items: center;
      padding: 2rem;
      justify-content: space-between;

      .button {
        width: auto;
      }

      .job-header {
        flex-wrap: nowrap;
      }
    }

  }
}