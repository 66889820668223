.work-filter {
  position: relative;
  // display: grid;
  // grid-gap: calc(var(--global-padding) / 2);
  margin: var(--global-padding);
  margin-bottom: 0;
  // grid-template-columns: repeat(1, 1fr);

  padding-top: 4rem;

  @include respond-to('medium') {
    grid-template-columns: repeat(2, 1fr);
  }

  &-resultnote {
    margin-top:calc(var(--global-padding) * 1);
  }

  &.singlename-active {
    .project-filter-singlename {
      display: flex;
    }
    .project-filter-services, .project-filter-names {
      pointer-events: none;
      opacity: 0.4;
    }
  }


  &-tags {

    .info {
      display: flex;

      &.hidden {
        opacity: 0;
        pointer-events: none;
      }

      .label {
        text-transform: uppercase;
        margin-right: 1rem;
      }

      .reset {
        background-color: white;
        color: black;
        padding: 0 0.5rem;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    .options {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      flex-shrink: 0;
      margin-top: calc(var(--global-padding) / 2);
      min-height: calc(var(--global-padding) * 2);

      @include respond-to('medium') {
        gap: 1rem;
      }

      .button {
        cursor: pointer;
        outline: inherit;
        font-family: var(--new-spirit);
        font-size: var(--button-large);
        line-height: 1;
        padding: 8px 16px;

        &.active {
          color: var(--surface-primary);
          background-color: var(--text-primary);

          &:hover {
            border-color: var(--text-primary);
          }
        }
      }
    }
  }
}
