.storytelling {
  width: 100%;
  display: grid;
  position: relative;
  grid-template-columns: 1fr;

  @include respond-to('medium') {
    grid-template-columns: 1fr 1fr;
  }

  .lottie-container, .storytelling-section {
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        // Swap odd and even child positions
        &:nth-child(odd) {
          grid-row: #{$i + 1}; // For 1-based index, add 1 to $i
          @include respond-to('medium') {
            order: initial;
            grid-row: initial;
          }
        }

        &:nth-child(even) {
          grid-row: #{$i - 1}; // For 1-based index, subtract 1 from $i
          @include respond-to('medium') {
            order: initial;
            grid-row: initial;
          }
        }

      }
    }
  }

  .lottie-container {
    transition: opacity 300ms;
    // border: 1px solid blue;
    max-height: 50lvh;
    width: 100%;
    opacity: 1;
    background-color: var(--white);

    @include respond-to('medium') {
      opacity: 0;
      position: sticky;
      top: 0;
      max-height: none;
      height: 100vh;
      padding: 10lvh 0;

      &:nth-child(2) {
        opacity: 1;
      }
    }
  }

  &-section {
    // border: 1px solid green;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    justify-content: center;
    flex-direction: column;
    scroll-snap-align: start;
    width: 100%;
    margin-bottom: 128px;

    @include respond-to('medium') {
      margin-bottom: 0;
      min-height: 100vh;
    }
  }

  &-counter {
    display: flex;
    // gap: 8px;
    align-items: center;

    .counter-text {
      margin-right: 8px;
    }

    .counter-circle {
      width: 24px;
      height: 12px;
      // border-radius: 50%;
      background-color: var(--grey);

      &:nth-child(2) {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &.is-active {
        background-color: var(--violet-light);
      }
    }
  }

  &-footer {
    display: none;

    @include respond-to('medium') {
      display: flex;
      gap: 12px;
      align-items: center;
      // margin-top: 16px;
      height: 0;
      position: sticky;
      bottom: 56px;
    }
  }
}
