.header {
  position: fixed;
  display: flex;
  width: 232px;
  // gap: 6rem;
  top: 1rem;
  left: 1rem;
  z-index: 1000;
  // left: 50%;
  // transform: translate3D(-50%, 0, 0);
  border-radius: 29px;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  width: calc(100% - 2rem);
  justify-content: space-between;
  // transition: all 800ms cubic-bezier(0.87, 0, 0.13, 1);
  //transition-delay: 200ms;
  max-width: 320px;
  overflow: hidden;
  // @include respond-to(small) {
  //   width: 448px;
  // }

  @include respond-to(small) {
    top: 2rem;
    left: 2rem;
  }

  @media (min-width: 860px) {
    border-radius: 50px;
    justify-content: flex-start;
    align-items: center;
    left: 1rem;
  }

  @include respond-to(large) {
    padding: 0.75rem 1.5rem 0.75rem 3rem;
    left: calc(var(--general-padding-horizontal) - 3rem);
  }

  @include respond-to(xxl) {
    padding: 0.75rem 1.5rem 0.75rem 3rem;
    left: calc(50% - (var(--general-maxwidth) / 2) - 3rem);
  }

  &.is-open {
    height: 160px;
    @media (min-width: 860px) {
      height: auto;
    }

    .header-menu {
      opacity: 1;
    }
  }

  @media (min-width: 860px) {
    &.expanded,
    &:hover {
      width: calc(100% - 2rem);
      max-width: 1200px;
      transition-delay: 0ms;
      padding: 1.5rem 1.5rem 1.5rem 2rem;
      
      @media (min-width: 860px) {
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
      }
      
      @include respond-to(large) {
        padding: 1.5rem 1.5rem 1.5rem 3rem;
        width: 80vw;
        min-width: 940px;

      }
      // top: 1.25rem;
      // left: 35%; //optional to left align

      /*.logo {
        height: 42px;
      }*/

      .header-border {
        transition: box-shadow 800ms cubic-bezier(0.87, 0, 0.13, 1) !important;
        box-shadow: 0 0 0 0px rgba(74, 0, 36, 1) !important;
      }

      .header-menu {
        opacity: 1;
        transition: opacity 600ms cubic-bezier(0.87, 0, 0.13, 1);
        transition-delay: 400ms;
      }
    }
    
  }

  .logo {
    //transform: scale(1);
    display: block;
    height: 35px;
    width: 190px;
    position: relative;
    transition: height 800ms cubic-bezier(0.87, 0, 0.13, 1), color 200ms;
    cursor: pointer;
    color: var(--text-primary);

    @include respond-to(large) {
      height: 42px;
      width: 223px;
    }

    // &:hover {
    //   color: var(--navigation-active);
    // }
  }

  .burger {
    @media (min-width: 860px) {
      display: none;
    }
  }

  &-border {
    position: absolute;
    pointer-events: none;
    border-radius:29px;
    inset: 0;
    background: var(--surface-secondary);
    box-shadow: 0 0 0 0px var(--text-primary);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);

    @media (min-width: 860px) {
      border-radius: 50px;
    }
  }

  &-menu {
    position: absolute;
    display: flex;
    font-size: 22px;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    font-family: "New Spirit";
    line-height: 100%;
    opacity: 0;
    transition-delay: 0ms;
    width: calc(100% - 3rem);
    flex-wrap: wrap;
    top: 66px;
    left: 1.5rem;
    
    @include respond-to('small') {
      font-size: 22px;
    }
      
    @media (min-width: 860px) {
      width: auto;
      gap: 0;
      flex-wrap: nowrap;
      right: 1.5rem;
      left: auto;
      top: auto;
      justify-content: flex-start;
      transition: opacity 300ms cubic-bezier(0.65, 0, 0.35, 1);
    }

    @include respond-to('large') {
      font-size: 26px;
    }

    @include respond-to('wide') {
      // gap: 2.5rem;
    }

    &-item,
    &-button {
      display: block;
      &:after {
        content: " ";
        display: block;
        position: absolute;
        left: -8px;
        border-radius: 50%;
        width: 4px;
        height: 4px;
        background-color: var(--navigation-active);
        opacity: 0;
        transition: opacity 200ms;
        pointer-events: none;
        transform: scale(0.5);
        transition: opacity 200ms, transform 200ms;

        @media (min-width: 860px) {
          width: 8px;
          height: 8px;
          left: -4px;
        }

        // @include respond-to('wide') {
        //   left: -18px;
        // }
      }

      &:hover {
        color: var(--navigation-active);
        border-color: var(--navigation-active);
      }

      &.active {
        color: var(--navigation-active);
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    &-item,
    &-button,
    &-language {
      color: var(--text-primary);
      display: flex;
      align-items: center;
      transition: color 200ms;
      position: relative;
      cursor: pointer;
    }
    
    &-item {
      @media (min-width: 860px) {
        margin-right: 20px;
        padding-right: 10px;
        padding-left: 10px;
        padding-top: 9px;
        padding-bottom: 9px;
      }
    }

    &-button {
      padding: 8px 16px;
      word-break: nowrap;
      border: 1px solid var(--text-primary);
      border-radius: 30px;
      transition: border-color 200ms, color 200ms;

      @media (min-width: 860px) {
        padding: 8px 24px;
      }

      &:hover {
        border-color: var(--miscellaneous-navigation-active);
      }
    }

    &-item-contact, &-item-kontakt {
      padding: 8px 16px;
      border: 1px solid var(--text-primary);
      border-radius: 30px;
      width: 33%;
      flex-grow: 1;
      text-align: center;
      transition: border-color 200ms, color 200ms;
      justify-content: center;

      @media (min-width: 860px) {
        padding: 8px 24px;
        width: auto;
        flex-grow: 0;
        // margin-left: -16px;
        margin-right: 1rem;
      }

      &.active {
        border: 1px solid transparent;
      }

      &::after {
        left: 1vw;
        
        @media (min-width: 350px) {
          left: 16px;
        }

        @media (min-width: 860px) {
          left: 6px;
        }
      }

    }

    &-language {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 3px;
      padding-left: 12px;
      border-radius: 30px;
      border: 1px solid var(--text-primary);

      &--de {
        padding: 3px;
        padding-right: 12px;
      }

      .language-active {
        background-color: var(--text-primary);
        color: var(--surface-secondary);
        border-radius: 30px;
        padding: 5px 12px;
      }
    }
  }
}