.image-w-button {
  position: relative;
  display: block;
  // width: 220px;
  width: 100%;
  // height: 100%;
  // --radius: 30px;
  // --radius: var(--general-radius);
  --radius: 20px;
  // overflow: hidden; //breaks notch position on safari ios for some reason

  &.has-default-button { //needs increased radius
    // --radius: var(--general-radius);
    --radius: 30px;
  }
  
  @media (max-width: 960px) {
    --radius: 20px;
    &.has-default-button {
      --radius: 20px;
    }
  }

  .single-image, .video-autoplay-wrapper {
    width: 100%;
    position: relative;
    display: block;
    border-radius: var(--radius);
    overflow: clip;
  }

  img {
    display: block;
    position: relative;
  }

  @media (max-width: 960px) {
    .button {
      // @extend .button-size-small;
      padding: 8px 16px; //previously 7px 16px
      font-size: var(--button-small);

      &.button-icon-only {
        padding: 8px;
      }

      .button-text {
        padding: 1px 0;
      }

      svg {
        width: 16px;
        height: 16px;
        min-width: 16px;
        stroke-width: 1.5;
      }
    }
  }
}