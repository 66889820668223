/* By Athlon Front-End Team */

/* CSS Reset */
@import 'base/reset';

/* CSS print */
@import 'base/print';

/* Fonts */
@import 'base/fonts';

/* Mixins, helpers and functions */
//@import 'base/mixins';
//(imported in vars.scss)

/* Core variables */
@import 'base/vars';



/* Vendor */

/* Components */
@import '../components/header/header';
@import '../components/button/button';
@import '../components/hero/hero';
@import '../components/credits/credits';
@import '../components/video/video';
@import '../components/contactprofile/contactprofile';
@import '../components/foundervideo/foundervideo';
@import '../components/footer/footer';
@import '../components/single-image/single-image';
@import '../components/image-w-button/image-w-button';
@import '../components/notch/notch';
@import '../components/quote/quote';
@import '../components/list/list';
@import '../components/awardlist/awardlist';
@import '../components/line/line';
@import '../components/iconlabel/iconlabel';
@import '../components/textcard/textcard';
@import '../components/card/card';
@import '../components/features/features';
// @import '../components/mobilemenu/mobilemenu';
@import '../components/contactform/contactform';
@import '../components/contactinfo/contactinfo';
@import '../components/checkbox/checkbox';
@import '../components/storytelling/storytelling';
// @import '../components/scrollfadein/scrollfadein';
@import '../components/chartadervielfalt/chartadervielfalt';
@import '../components/work-filter/work-filter';
@import '../components/gradient/gradient';
@import '../components/logowall/logowall';
@import '../components/burger/burger';
@import '../components/accordion/accordion';
@import '../components/singlestep/singlestep';
@import '../components/steps/steps';
@import '../components/jobs/jobs';
@import '../components/teaser/teaser';
@import '../components/work-grid/work-grid';
@import '../components/writer/writer';
@import '../components/people/people';
@import '../components/verticalspacer/verticalspacer';
@import '../components/matomooptout/matomooptout';
@import '../components/captcha/captcha';

/* Browser specific styles */
@import 'dirty/hacks';

html {
  // scroll-behavior: smooth;
  scroll-behavior: auto;
  font-size: 16px;
}


body {
  color: var(--text-primary);
  background-color: var(--surface-primary);
  font-family: var(--bw-gradual);
  font-size: 0.8rem;
  line-height: 1.5;
  padding: 0;
  transition: all 0.5s ease;

  @include respond-to(medium) {
    font-size: 1rem;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &::selection {
    color: white;
    background: var(--surface-secondary);
  }
}

img {
  width: 100%;
}

svg {
  max-width: 100%;
}

.resize-helper {
  position: fixed;
  pointer-events: none;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  height: 100lvh;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  font-family: var(--bw-gradual);
  line-height: 100%;
  text-transform: uppercase;
}
h1 {
  font-size: var(--h1);
}
h2 {
  font-size: var(--h2);
}
h3 {
  font-size: var(--h3);
}
h4 {
  font-size: var(--h4);
}
.teaser-text {
  font-size: var(--teaser-text);
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-family: var(--bw-gradual);
  line-height: 125%;
}
.copy-large, .largetext, .large-text { //todo: clean classes
  font-size: var(--copy-large);
  font-family: var(--new-spirit);
  letter-spacing: -0.5%;
  line-height: 115%;
  margin: 0;
  padding: 0;
}

p, .copy-text {
  font-size: var(--copy-text);
  margin: 0;
  padding: 0;
  line-height: 145%;

  a {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}

strong {
  font-weight: bold;
}

.remove-height-on-desktop {
  @include respond-to(medium) {
    height: 0;
  }
}

.container {
  --gutter: 2rem;
  z-index: 10;
  display: block;
  position: relative;

  &.template-project {
    --gutter: 1rem;
  }
}

main {
  position: relative;
  display: block;
  padding-top: var(--general-padding-top);
  padding-bottom: var(--general-padding-top);
  // padding-bottom: 4rem;
  padding-left: var(--general-padding-horizontal);
  padding-right: var(--general-padding-horizontal);
  
  background: var(--surface-primary);
  // border-bottom-left-radius: var(--general-radius);
  // border-bottom-right-radius: var(--general-radius);
  border-bottom-left-radius: 45px; //same radius as header
  border-bottom-right-radius: 45px;
  min-height: 100svh;
  // margin-bottom: calc(100svh - var(--general-padding-top));
  overflow: clip;
  z-index: 100;
}

.footer-spacer {
  width: 100%;
  height: calc(100svh - var(--general-padding-top));
  pointer-events: none;
}

.layout { //contains all grids
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
  max-width: var(--general-maxwidth);
  margin-left: auto;
  margin-right: auto;
  font-size: var(--copy-text);
}

.grid {
  // --gutter: 1rem; // gutter is defined on container or template
  display: flex;
  flex-direction: column;
  gap: var(--gutter);
  margin-bottom: 2rem; //not sure if this is the best way to space sections

  position: relative; //for absolute gradients in background

  &.reverseonmobile {
    flex-direction: column-reverse;
  }

  &.no-container {
    .column {
      container: unset !important;
    }
  }

  &.indent {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    @include respond-to('wide') {
      width: 80%;
    }
  }

  @include respond-to('medium') {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: var(--gutter);
  }

  .column-2 {
    grid-column: span 4;
  }
  
  @include respond-to(xl) {
    .column-2 {
      grid-column: span 2;
    }
  }
}



.column {
  container-type: inline-size;
  --columns: 12;
  display: flex;
  position: relative;
  flex-direction: column;
  // gap: var(--gutter);
  gap: 1rem;
  align-items: flex-start;
  grid-column: span var(--columns);
  // height: 100%;

  &.empty-column {
    display: none;
    container:unset;
    
    @include respond-to('medium') {
      display: block;
    }
  }

  .splitcolumn {
    display: flex;
    margin-top: calc(var(--gutter) - 1rem);
    gap: var(--gutter);
    width: 100%;
    height: 100%;

    &-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .single-image, .video-autoplay-wrapper {
      &.stretch {
        min-height: 100px;
        height: 100%;
        display: block;
        position: relative;
        
        img {
          height: 100%;
          position: absolute;
          inset: 0;
          object-fit: cover;
        }
      }
    }
  }
  
}


.sticky {
  position: sticky;
  top: var(--general-padding-top);
}

.sticky-images {
  figure {
    position: sticky;
    top: var(--general-padding-top);
  }

  .image-w-button {
    position: sticky;
    top: var(--general-padding-top);
    
    figure {
      top: auto;
      position: relative
    }
  }
}

.sticky-text {
  h1, h2, h3, h4, h5, p, .largetext, .large-text {
    position: sticky;
    top: var(--general-padding-top);
  }
}

.wrap > .column {
  flex-flow: wrap;
}

.no-margin {
  margin: 0;
}