.singlestep {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &-header {
    display: flex;
    gap: 1rem;
    align-items: center;
  }

  &-number {
    line-height: 1;
    white-space: nowrap;
  }

  &-line {
    width: 100%;
    height: 1px;
    background-color: var(--text-primary);
    display: block;
  }
}
