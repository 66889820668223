/**
 * Variables
*/

$breakpoints: (
  small: 480px,
  medium: 720px,
  large: 960px,
  wide: 1200px,
  xl: 1440px,
  xxl: 1800px
);

// Loop through the map and define CSS variables
// :root {
//   @each $key, $value in $breakpoints {
//     --breakpoint-#{$key}: #{$value};
//   }
// }

/* Mixins, helpers and functions */
@import './mixins';


:root {
  /* Primitives */
  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
  --beige: rgb(241, 243, 241);
  --blue: rgb(36, 61, 195);
  --burgund: rgb(93, 10, 43);
  --burgund-85: rgba(67, 0, 32, 0.85);
  --cyan: rgb(150, 249, 255);
  --dark-blue: rgb(27, 46, 149);
  --dark-burgund: rgb(51, 6, 24);
  --dark-grey: rgb(67, 67, 64);
  --light-orange: rgba(253, 87, 31, 0.2);
  --off-black: rgb(33, 33, 31);
  --off-black-70: rgba(44, 44, 40, 0.7);
  --orange: rgb(253, 87, 31);
}

.light-warm {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--cyan);
  --surface-primary: var(--beige);
  --surface-secondary: var(--burgund-85);
  --text-active: var(--orange);
  --text-disabled: var(--white);
  --text-hover: var(--burgund);
  --text-primary: var(--orange);
}

.dark-cold {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--cyan);
  --surface-primary: var(--off-black);
  --surface-secondary: var(--off-black-70);
  --text-active: var(--white);
  --text-disabled: var(--dark-grey);
  --text-hover: var(--orange);
  --text-primary: var(--cyan);
}

.dark-warm {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--orange);
  --surface-primary: var(--burgund);
  --surface-secondary: var(--off-black-70);
  --text-active: var(--white);
  --text-disabled: var(--dark-burgund);
  --text-hover: var(--orange);
  --text-primary: var(--cyan);
}

.ice-cold {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--cyan);
  --surface-primary: var(--blue);
  --surface-secondary: var(--off-black-70);
  --text-active: var(--white);
  --text-disabled: var(--dark-blue);
  --text-hover: var(--orange);
  --text-primary: var(--cyan);
}



:root {
  /* Font families */
  --bw-gradual: 'Bw Gradual', 'Helvetica', sans-serif;
  --new-spirit: 'New Spirit', serif;


  /* Text-size styles */
  --h1: 32px;
  --h2: 32px;
  --h3: 26px;
  --h4: 20px;
  --copy-large: 18px;
  --copy-text: 14px;
  --button-large: 16px;
  --button-small: 14px;
  
  @include respond-to(medium) {
    --h1: 36px;
    --h2: 36px;
    // --h3: 26px;
    // --h4: 20px;
    // --copy-large: 18px;
    // --copy-text: 14px;
    // --button-large: 16px;
    // --button-small: 14px;
  }

  @include respond-to(large) {
    // --h1: 48px;
    // --h2: 48px;
    --h1: 64px;
    --h2: 64px;
    --h3: 32px;
    --h4: 26px;
    --copy-large: 22px;
    // --copy-text: 16px;
    --copy-text: 16px;
    --button-large: 18px;
    // --button-small: 14px;
  }

  @include respond-to(wide) {
    --h1: 72px;
    --h2: 72px;
    --h3: 48px;
    --h4: 32px;
    // --copy-large: 22px;
    // --copy-text: 14px;
    // --button-large: 18px;
    // --button-small: 14px;
  }

  @include respond-to(xl) {
    --h1: 88px;
    --h2: 88px;
    // --h3: 48px;
    // --h4: 32px;
    // --copy-large: 22px;
    // --copy-text: 14px;
    // --button-large: 18px;
    // --button-small: 14px;
  }

  @include respond-to(xxl) {
    // --h1: 88px;
    // --h2: 88px;
    --h1: 96px;
    --h2: 96px;
    --h3: 64px;
    --h4: 32px;
    --copy-large: 26px;
    // --copy-text: 14px;
    --button-large: 26px;
    // --button-small: 14px;
  }
}


/* Spacing styles & Sizes */
:root {
  --general-padding-top: 94px;
  --general-padding-horizontal: 1rem;
  --general-radius: 10px;
  --general-maxwidth: 2000px; //--general-padding-horizontal * 2

  @include respond-to('small') {
    --general-padding-top: 118px;
    --general-padding-horizontal: 2rem;
  }

  @include respond-to('medium') {
    --general-radius: 20px;
  }

  @media (min-width: 860px) {
    --general-padding-top: 142px;
  }

  @include respond-to('large') {
    --general-padding-top: 9.625rem;
    --general-padding-horizontal: 4rem;
    --general-radius: 30px;
  }
  
  // @include respond-to('wide') {
  //   --general-padding-horizontal: 6rem;
  // }
  
  // @include respond-to('xl') {
  //   --general-padding-horizontal: 6rem;
  // }
  
  @include respond-to('xxl') {
    --general-padding-horizontal: 6rem;
    --general-maxwidth: calc(1800px - 12rem); //--general-padding-horizontal * 2
  }
}


/* Effect styles */
:root {
  --popover-shadow:  0px 0px 0px rgba(63, 57, 25, 0.1), 0px 1px 2px rgba(63, 57, 25, 0.1), 0px 3px 3px rgba(63, 57, 25, 0.09), 0px 6px 4px rgba(63, 57, 25, 0.05), 0px 11px 4px rgba(63, 57, 25, 0.01), 0px 17px 5px rgba(63, 57, 25, 0);
}