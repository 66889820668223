
.burger {
	// position: absolute;
	// left: auto;
	// top: 10px;
	// right: 10px;
	// bottom: auto;
	// border: 1px solid pink;
    cursor: pointer;
    height: 35px;
}

.burger-icon {
	height: 20px;
	width: 32px;
	// border: 1px solid green;
	position: relative;
	box-sizing: border-box;
	margin: 8px 0 8px 10px;
	
	span, &:before, &:after {
		content: " ";
		display: block;
		position: absolute;
        height: 3px;
        border-radius: 2px;
		width: 100%;
		background-color: var(--text-primary);
		transition: all 200ms;
		transform-origin: center center;
	}
	
	span {
		top: 50%;
		transform: translateY(-50%);
	}
	
	&:before {
		top: 0;
		bottom: auto;
	}

	&:after {
		top: auto;
		bottom: 0;
	}
	
	&.is-active {
		&:before {
			top: 50%;
			transform: translateY(-50%) rotate(-45deg);
		}
		
		span {
			opacity: 0;
			transform: translateX(-10%);
		}
		
		&:after {
			bottom: 50%;
			transform: translateY(50%) rotate(45deg);
		}
	}
}