.accordion {
    overflow: hidden;
    width: 100%;
  
    .accordion-item {
      background-color: var(--surface-secondary);
      border-radius: var(--general-radius);
      margin-bottom: 1rem;
      padding: 2rem;
      display: flex;
      width: 100%;
  
      &:last-child {
        margin-bottom: 0;
      }
  
      &.closed {
          .accordion-icon-closed {
            display: block;
          }
          .accordion-icon-open {
            display: none;
          }
          .accordion-content {
            display: none;
          }
      }

      &.open {
          .accordion-icon-closed {
            display: none;
          }
          .accordion-icon-open {
            display: block;
          }
          .accordion-content {
            display: flex;
          }
      }
  
      .accordion-icon {
        display: flex;
        // align-items: center;
        cursor: pointer;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        
        @include respond-to('wide') {
          margin-top: 3px;
        }
      }
  
      .accordion-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 1rem;
        margin-top: 2px;

        @include respond-to('large') {
          margin-top: 0;
        }
      }
  
      .accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: color 300ms;
        transition-delay: 0;

        &:hover {
          color: var(--white);
        }
  
        h4 {
          margin: 0;
          vertical-align: top;

          &::selection {
            color: var(--text-primary);
          }
        }
  
        .accordion-number {
          font-weight: bold;
          display: inline;
          vertical-align: top;
          line-height: 120%;

          &::selection {
            color: var(--text-primary);
          }
          
          @include respond-to('large') {
              line-height: 150%;
          }
        }
      }
  
      .accordion-content {
        display: none;
        padding-top: 1rem;
        color: var(--navigation-active);
        flex-direction: column;
        gap: 1rem;

        @container (min-width: 600px) {
          flex-direction: row;
        }

        .accordion-text {
          width: 100%;
          display: flex;
          flex-direction: column;
          // gap: 1rem;
        }
      
        p, h1, h2, h3, h4, h5, h6, ul, ol {
          margin-bottom: 1rem;
          position: relative;
          display: block;
          top: auto;

      
          &:last-child {
            margin-bottom: 0;
          }
        }
        // p {
        //     &::selection {
        //         color: var(--navigation-active);
        //     }
        // }

        .accordion-image {
          width: 100%;
          flex-shrink: 0;
          max-width: 320px;

          @container (min-width: 600px) {
            width: 240px;
          }

          @container (min-width: 660px) {
            width: 320px;
            max-width: auto;
          }

          &--left {
            order: -1;
          }

        }
      }
    }
  }
  