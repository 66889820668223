.chartadervielfalt{
  width: 180px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;

  // align-items: center;

  @include respond-to('small') {
    align-items: flex-end;
  }

  @include respond-to('large') {
    max-width: 820px;
  }

  @include respond-to('xxl') {
    max-width: 1200px;
  }

  &-image {
    width: 180px;
  }
}
