.steps {
  width: 100%;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  // border: 1px solid blue;
  justify-content: stretch;


  .singlestep {
    width: 288px;
    flex-grow: 1;
  }

  @container (min-width: 640px) {
    // border: 1px solid red;

    .singlestep {
      width: 45%;
    }
  }

  @container (min-width: 1200px) {
    // border: 1px solid green;

    .singlestep {
      width: 30%;
      width: 188px;
    }
  }
}
