.contact-headline {
  width: 100%;
  margin-top: calc(16px * 4);
  text-align: center;
  padding: 0 16px;
}

.contact-error {
  width: 100%;
  text-align: center;
  color: white;
  background-color: red;
  border-radius: 10px;
  padding: 16px;
}

.alert.error {
  background-color: red;
  color: white;
  // width: 100%;
  font-size: 12px;
  text-align: center;
  padding: 12px;
  border-radius: 8px;
  border-top-left-radius: 0;
  margin-left: 48px;
  margin-top: 12px;
  // margin: calc(16px * 0.5);
  position: absolute;
  top: 100%;
  left: 0;
}

.contact-success {
  display: none;
  margin-bottom: calc(16px * 4);
}

.contactform {
  padding: 32px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 64px;
  overflow: hidden;
  border-radius: var(--general-radius);
  background-color: var(--off-white);
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: 1px solid var(--text-disabled);

  @include respond-to('medium') {
    padding: 64px 32px;
  }

  &-intro {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    p, h4 {
      max-width: 380px;
    }
  }

  .fields-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  .field {
    background-color: var(--off-white);
    position: relative;
    font-size: var(--copy-text);
    font-family: 'Labil Grotesk', 'Helvetica', sans-serif;

    &--message {
      grid-column: span 2;
    }

    &--alert {
      // background-color: rgba(255,0,0,0.4);
      // color: white;
      // border-radius: 10px;
    }

    label {
      display: none;
    }

    &-bottom {
      grid-column: span 2;

      @include respond-to('large') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      label {
        font-size: var(--copy-large);
        position: relative;
        display: block;
      }
    }

    input, textarea {
      background-color: transparent;
      width: 100%;
      color: var(--text-primary);
      padding: 8px 0;
      // font-size: var(--copy-large);
      font-size: 18px;
      font-family: var(--bw-gradual);
      outline: none;
      border: 0px solid transparent;
      border-bottom: 1px solid var(--text-primary);
      border-radius: 0 !important;

      &::placeholder {
        color: var(--text-primary);
        opacity: 0.6;
      }
    }

    textarea {
      // height: 45vw;
      min-height: 100px;
      max-height: 50vh;
      // margin-bottom: calc(16px * 4);
      margin-bottom: calc(16px * 1);
      resize: none;
    }
  }

  .button {
    width: 100%;
    border: none;
    outline: none;

    @include respond-to('large') {
      width: auto;
      flex-shrink: 0;
    }
  }

  .honeypot {
    position: absolute;
    left: -9999px;
  }

}
