.template-home {
    .hero {
        border-bottom: 1px solid var(--text-primary);
    }
}

.hero {
    position: relative;
    // align-items: center;
    padding-bottom: 2rem;
    max-width: var(--general-maxwidth);
    margin-left: auto;
    margin-right: auto;

    .image-w-button {
        grid-column: span 12;
        width: 100%;

        .single-image, img {
            height: 100%;
            max-height: 75svh;
            min-height: 100%;
            object-fit: cover;
        }
    }

    &-year {
        @include respond-to('medium') {
            text-align: right;
        }

        @include respond-to('large') {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    &-text {
        display: flex;
        position: relative;
        flex-direction: column;
        gap: 1rem;
        grid-column: span 12;
        height: 100%; //remove to center it again

        p {
            max-width: 480px;
        }

        @include respond-to('medium') {
            flex-direction: row;
            align-items: flex-end;
            gap: 2rem;
        }
    }

    &-textwrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        height: 100%;
        justify-content: center; //remove to spread it again

        // :nth-child(2) { //enable this to spread text and headline apart
        //     margin-top: auto;
        // }
    }

    
    &-portrait {
        .video-autoplay-wrapper {
            // height: auto !important;

            // .video-autoplay {
            //     height: auto !important;
            // }
        }

        @include respond-to('medium') {
            .video-autoplay-wrapper {
                max-height: calc(100svh - var(--general-padding-top) - 2rem) !important;
                min-height: 100%;
            }
            .image-w-button {
                grid-column: span 4;
            }

            .hero-text {
                grid-column: span 8;
            }
        }
    }
}
