.gradient {
    &:before {
        content: " ";
        display: block;
        width: 100vw;
        height: 100vw;
        max-width: 2000px;
        max-height: 2000px;
        position: absolute;
        // background: radial-gradient(var(--surface-gradient-highlight), var(--surface-primary));
        background: radial-gradient(
            circle,
            var(--surface-gradient-highlight) 0%,
            var(--surface-primary) 70%,
            var(--surface-primary) 100%
        );
        // background: var(--surface-gradient-highlight);
        // background: radial-gradient(var(--surface-primary), var(--surface-primary));
        border-radius: 50%;
        opacity: 0.8;
        // filter: blur(80px);
        z-index: -1;
        

        @include respond-to(medium) {
            // width: 70vw;
            // height: 70vw;
            // filter: blur(400px);
        }
    }

    &-top-right {
        &:before {
            top: 0;
            right: 0;
            transform: translate3d(50%, -50%, 0);
        }
    }
   
    &-top-left {
        &:before {
            top: 0;
            left: 0;
            transform: translate3d(-50%, -50%, 0);
        }
    }

    &-bottom-left {
        &:before {
            bottom: 0;
            left: 0;
            transform: translate3d(-50%, 50%, 0);
        }
    }

    &-bottom-right {
        &:before {
            bottom: 0;
            right: 0;
            transform: translate3d(50%, 50%, 0);
        }
    }

    &-center {
        &:before {
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
        }
    }
}