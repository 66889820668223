.teaser {
    display: block;
    position: relative;
    // width: 440px;
    width: 100%;
    overflow: clip;
  
    --radius: 20px;
    --transition-transform: transform 500ms cubic-bezier(0.25, 1, 0.5, 1);
  
    .notch--expandable {
      transform: translate3d(0, 100%, 0);
      transition: var(--transition-transform);
    }

    .button-icon-only {
        // display: none;
        position: absolute;
        right: -38px;
        transform: scale(0.5) translate3d(0,0,0);
        opacity: 0;
        transition: var(--transition-transform), opacity 300ms, right 300ms;
        transition-delay: 0;
        will-change: right, transform, opacity;

        // @include respond-to('medium') {
        //   display: flex;
        // }
    }

    .expanding-button-spacer {
        // display: none;
        width: 0;
        transition: width 300ms;
        will-change: width;

        // @include respond-to('medium') {
        //   display: block;
        // }
    }
  
    @media (hover: hover) and (pointer: fine) {
      &:hover {
          .teaser-fg {
              transform: scale(1.05) translate3d(0, -2.5%, 0);
          }

          .teaser-bg {
              transform: scale(1.05) translate3d(0, 2.5%, 0);
          }

          .notch--expandable {
              transform: translate3d(0, 0, 0);
          }

          .button-icon-only {
              // position: absolute;
              right: 10px;
              transform: scale(1) translate3d(0,0,0);
              opacity: 1;
              // transition-delay: 50ms;
          }

          .expanding-button-spacer {
              width: 48px;
          }
      }
    }

    @media (hover: none) and (pointer: coarse) {
      //on touch, same state as on hover
      .teaser-fg {
        transform: scale(1.05) translate3d(0, 5%, 0);
      }
  
      .teaser-bg {
        transform: scale(1.05) translate3d(0, -2.5%, 0);
      }
  
      .notch--expandable {
        transform: translate3d(0, 0, 0);
      }
    }

    &-visual {
        display: block;
        position: relative;
        aspect-ratio: 3 / 2;
        width: 100%;
        border-radius: var(--radius);
        overflow: clip;
        background: white;
    
        img {
          display: block;
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
    
        .teaser-bg {
          transform: scale(1.06) translate3d(0, -2.5%, 0); //.06 to prevent glitches
          transition: var(--transition-transform);
          will-change: transform;
        }
    
        .teaser-fg {
          position: absolute;
          inset: 0;
          transform: scale(1.05) translate3d(0, 2.5%, 0);
          transition: var(--transition-transform);
          will-change: transform;
        }
      }
    }