
.work-grid, .work-grid-static {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: var(--gutter);
  margin-top: 1rem;
  width: 100%;

  @include respond-to('medium') {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond-to('wide') {
    grid-template-columns: repeat(3, 1fr);
  }
  
  // @include respond-to('xxl') {
  //   grid-template-columns: repeat(4, 1fr);
  // }
}

.work-grid-load-all {
  width: 100%;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  &.visible {
    display: flex;
  }
}

.work-grid-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}