.logo-wall {
    display: grid;
    gap: 1rem;
    justify-content: space-between;
    width: 100%;
    place-content: center;

    &.logowall-one-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        
        @include respond-to(medium) {
            flex-wrap: nowrap;
        }
        
        .logo-item {
            width: 80px;
            min-width: 80px;
            
            @include respond-to(medium) {
                width: auto;
            }
        }
    }
  }
  
  .logo-item {
    text-align: center;
    width: 100%;
  }
  
  .logo-item img, .logo-item svg {
    max-width: 100%;
    display: block;
    height: auto;
    max-height: 148px;
    object-fit: contain;
  }
  
  /* Responsive grid layout */
  @media (min-width: 1200px) {
    .logo-wall {
      grid-template-columns: repeat(8, 1fr);
    }
  }
  
  @media (max-width: 1199px) and (min-width: 768px) {
    .logo-wall {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 767px) {
    .logo-wall {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  