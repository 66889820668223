.contactinfo-wrapper {
  width: 100%;
  display: flex;
  gap: 32px;
  justify-content: space-between;
  margin: 64px auto;
  flex-direction: column;

  @include respond-to('small') {
    align-items: flex-end;
    flex-direction: row;
  }

  @include respond-to('large') {
    max-width: 820px;
  }

  @include respond-to('xxl') {
    max-width: 1200px;
  }
}

.contactinfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
