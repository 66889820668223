.notch {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;

  //style all rounded corners:
  &::before,
  .notch-row::after,
  &.notch--expandable .notch-row:first-of-type::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: var(--radius);
    height: var(--radius);
    background-color: var(--surface-primary);
    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='notch-tile' viewBox='0 0 20 20'%3E%3Cpath d='M0 20H20C8.95435 20 0 11.0457 0 0V20Z' fill='currentColor' /%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='notch-tile' viewBox='0 0 20 20'%3E%3Cpath d='M0 20H20C8.95435 20 0 11.0457 0 0V20Z' fill='currentColor' /%3E%3C/svg%3E");
    box-shadow: 0px 0px 0px 1px var(--surface-primary); //prevent glitches
  }

  //top corner
  &::before {
    left: 0;
    top: calc(var(--radius) * -1);
  }

  &-row {
    display: flex;
    position: relative;
    align-items: flex-end;
    min-height: calc(var(--radius) * 2);
    width: auto;
    max-width: calc(100% - (var(--radius) * 2));
    padding-top: 10px;
    // padding-right: calc(var(--radius) * 0.5);
    padding-right: 10px;
    padding-bottom: 1px;
    border-top-right-radius: var(--radius);
    background: var(--surface-primary);
    box-shadow: 0px 0px 0px 0.5px var(--surface-primary); //prevent glitches

    //notch on end of row (right)
    &::after {
      left: auto;
      top: auto;
      right: calc(var(--radius) * -1);
      bottom: 0;
    }
  }

  &--top-right {
    --radius: 20px;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    width: auto;
    align-items: flex-end;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--radius);

    &::before, .notch-row::after {
      -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='notch-tile' viewBox='0 0 20 20'%3E%3Cpath d='M0 20H20C8.95435 20 0 11.0457 0 0V20Z' fill='currentColor' transform='rotate(180, 10, 10)' /%3E%3C/svg%3E");
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='notch-tile' viewBox='0 0 20 20'%3E%3Cpath d='M0 20H20C8.95435 20 0 11.0457 0 0V20Z' fill='currentColor' transform='rotate(180, 10, 10)' /%3E%3C/svg%3E");
    }

    &::before {
      left: auto;
      top: auto;
      bottom: calc(var(--radius) * -1);
      right: 0;
    }

    .notch-row {
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 10px;
      padding-left: 10px;
      // padding-left: calc(var(--radius) * 0.5);
      max-width: none;
      border-top-right-radius: 0;
      border-bottom-left-radius: var(--radius);

      &::after {
        left: calc(var(--radius) * -1);
        top: 0;
        bottom: auto;
        right: auto; 
      }
    }
  }

  &--expandable {
    &::before {
      display: none;
    }
    .notch-row:first-of-type {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-100%);

      &::before {
        left: 0;
        top: calc(var(--radius) * -1);
      }
    }

    h3 {
      font-size: 18px;
    }

    @container (min-width: 320px) {  
      h3 {
        font-size: 22px;
      }
    }
    @container (min-width: 440px) {  
      h3 {
        font-size: 24px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  p {
    display: inline-block;
    line-height: 100%;
    margin: 0;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // word-wrap: nowrap;
    // overflow-wrap: break-word;
    color: var(--text-primary);
  }
}
