.matomooptout, #matomo-opt-out {
  padding: 1rem;
  background-color: white;
  border-radius: var(--general-radius);
  width: 100%;

  input {
    appearance: auto !important;
    margin: 3px 6px 3px 4px;
  }
}