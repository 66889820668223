.features {
  width: 100%;

  @include respond-to('large') {
    display: flex;
    gap: 32px;
    align-items: flex-start;
    margin-top: 32px;
  }

  #ipad {
    filter: drop-shadow(0px 8px 10px #bcbcbc) drop-shadow(0px 16px 20px #bcbcbc);
    z-index: -1;
    position: relative;
  }
}

.feature {
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 6px;
  border: 1px solid var(--dark-grey);
  transition: border 300ms, background-color 300ms;
  border-radius: 10px;
  cursor: pointer;
  min-width: 260px;

  @include respond-to('large') {
    min-width: 0;
    width: calc(50% - 8px);
  }

  &-icon {
    padding: 12px;
    border-radius: 8px;
    background-color: var(--grey);
    transition: background-color 300ms, color 300ms;
    flex-shrink: 0;

    svg {
      width: 24px;
      height: 24px;
      display: block;
      transition: color 150ms;
    }
  }

  &.active {
    background-color: var(--violet-light);

    .feature-icon {
      background-color: black;
      color: white;
    }
  }

}

.features-list {
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  // border: 1px solid red;
  width: 100vw;
  margin-left: -16px;
  padding: 0 16px;
  overflow-x: scroll;

  @include respond-to('medium') {
    margin-left: -32px;
    padding: 0 32px;
  }

  @include respond-to('large') {
    margin-left: 0;
    padding: 0;
    width: 100%;
    flex-direction: column;
  }
  @include respond-to('wide') {
    // margin: 32px 0;
  }
}

.features-showcase {
  width: 100%;
}


.feature-wrapper {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @include respond-to('large') {
    flex-direction: row;
  }
}

.features-caption {
  margin-top: 16px;
  min-height: 48px;
  text-align: center;
}






//remove scroll bar
.features-list {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.features-list::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
