.single-image {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  picture {
    width: 100%;
    display: flex;
  }

  img {
    border-radius: var(--general-radius);
  }
  
  &.stretch {
    @include respond-to('medium') {
      min-height: 100px;
      height: 100%;
      display: block;
      position: relative;
      
      img {
        height: 100%;
        position: absolute;
        inset: 0;
        object-fit: cover;
      }
    }
  }


  &.has-mobile {
    .desktop-image {
      display: none;
    }

    @include respond-to('medium') {
      .desktop-image {
        display: block;
      }
      .mobile-image {
        display: none;
      }
    }
  }
}