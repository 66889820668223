.iconlabel {
  display: flex;
  gap: 12px;
  align-items: center;
  // margin-top: 48px;

  &-icon {
    flex-shrink: 0;
    background-color: var(--grey);
    padding: 12px;
    border-radius: 8px;

    svg {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
}
