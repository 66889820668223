.writer {
    width: 100%;

    h1,h2,h3 {
        margin-top: 4rem;
        margin-bottom: 1rem;
    }

    h4 {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }

    ul {
        margin: 1rem 0;
    }

    li p {
        margin: 0;
    } 
}