ul {
  width: 100%;
  display: block;
  padding-left: 0;
  margin-left: 0;

  li {
    border-bottom: 1px solid var(--text-primary);
    list-style: none;
    padding: 8px 0;

    &:last-of-type {
      border-bottom: none;
    }
  }
}


// ol {
//   padding-left: 0 !important;
//   // counter-reset: list-item 0;
//   display: flex;
//   flex-direction: column;
//   gap: 32px;

//   --badge-size: 36px;

//   @include respond-to('medium') {
//     --badge-size: 48px;
//   }
// }

// ol>li {
//   position: relative;
//   font-size: var(--copy-text);
//   line-height: 140%;
//   // display: inline-flex;
//   // align-items: center;
//   // gap: 16px;
//   list-style: none;
//   padding-left: calc(var(--badge-size) + 16px);
//   // min-height: var(--badge-size);
// }

// ol>li::before {
//   background-color: #D0C5FB;
//   position: absolute;
//   left: 0;
//   font-size: var(--teaser-text);
//   // counter-increment: list-item 1;
//   content: counter(list-item);
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   width: var(--badge-size);
//   height: var(--badge-size);
//   border-radius: 50%;
//   font-weight: bold;
//   flex-shrink: 0;
//   top: 50%;
//   transform: translate3d(0, -50%, 0);
// }

// ol>li::marker {
//   display: none;
//   opacity: 0;
// }

// ul>li::marker {
//   color: #D0C5FB;
// }
