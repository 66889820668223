html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font: inherit;
  vertical-align: baseline;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary, main {
  display: block;
}

audio, canvas, video {
  display: inline-block;
}

body {
  margin: 0;
}

a {
  background: none;
  text-decoration: none;
}

button, input {
  line-height: normal;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  vertical-align: top;
  overflow: auto;
}

textarea:focus, input:focus {
  outline: 0;
}

input {
  appearance: none;
  margin: 0;
  padding: 0;
}

@media print {
  @page {
    margin: 1cm;
  }

  body {
    background: --white;
    color: #000;
  }

  a, blockquote, table, pre {
    page-break-inside: avoid;
  }

  ul, ol, dl {
    page-break-before: avoid;
  }
}

@font-face {
  font-family: New Spirit;
  src: url("NewSpirit-Regular.40e5bfec.otf") format("opentype"), url("NewSpirit-Regular.f88f433c.ttf") format("truetype"), url("NewSpirit-Regular.a13cc191.woff") format("woff"), url("NewSpirit-Regular.3b65b67c.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Bw Gradual;
  src: url("BwGradual-Regular.e4ba3d1f.otf") format("opentype"), url("BwGradual-Regular.81c92827.woff") format("woff"), url("BwGradual-Regular.3b7d12b0.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

:root {
  --black: #000;
  --white: #fff;
  --beige: #f1f3f1;
  --blue: #243dc3;
  --burgund: #5d0a2b;
  --burgund-85: #430020d9;
  --cyan: #96f9ff;
  --dark-blue: #1b2e95;
  --dark-burgund: #330618;
  --dark-grey: #434340;
  --light-orange: #fd571f33;
  --off-black: #21211f;
  --off-black-70: #2c2c28b3;
  --orange: #fd571f;
}

.light-warm {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--cyan);
  --surface-primary: var(--beige);
  --surface-secondary: var(--burgund-85);
  --text-active: var(--orange);
  --text-disabled: var(--white);
  --text-hover: var(--burgund);
  --text-primary: var(--orange);
}

.dark-cold {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--cyan);
  --surface-primary: var(--off-black);
  --surface-secondary: var(--off-black-70);
  --text-active: var(--white);
  --text-disabled: var(--dark-grey);
  --text-hover: var(--orange);
  --text-primary: var(--cyan);
}

.dark-warm {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--orange);
  --surface-primary: var(--burgund);
  --surface-secondary: var(--off-black-70);
  --text-active: var(--white);
  --text-disabled: var(--dark-burgund);
  --text-hover: var(--orange);
  --text-primary: var(--cyan);
}

.ice-cold {
  --navigation-active: var(--white);
  --surface-gradient-highlight: var(--cyan);
  --surface-primary: var(--blue);
  --surface-secondary: var(--off-black-70);
  --text-active: var(--white);
  --text-disabled: var(--dark-blue);
  --text-hover: var(--orange);
  --text-primary: var(--cyan);
}

:root {
  --bw-gradual: "Bw Gradual", "Helvetica", sans-serif;
  --new-spirit: "New Spirit", serif;
  --h1: 32px;
  --h2: 32px;
  --h3: 26px;
  --h4: 20px;
  --copy-large: 18px;
  --copy-text: 14px;
  --button-large: 16px;
  --button-small: 14px;
}

@media (width >= 720px) {
  :root {
    --h1: 36px;
    --h2: 36px;
  }
}

@media (width >= 960px) {
  :root {
    --h1: 64px;
    --h2: 64px;
    --h3: 32px;
    --h4: 26px;
    --copy-large: 22px;
    --copy-text: 16px;
    --button-large: 18px;
  }
}

@media (width >= 1200px) {
  :root {
    --h1: 72px;
    --h2: 72px;
    --h3: 48px;
    --h4: 32px;
  }
}

@media (width >= 1440px) {
  :root {
    --h1: 88px;
    --h2: 88px;
  }
}

@media (width >= 1800px) {
  :root {
    --h1: 96px;
    --h2: 96px;
    --h3: 64px;
    --h4: 32px;
    --copy-large: 26px;
    --button-large: 26px;
  }
}

:root {
  --general-padding-top: 94px;
  --general-padding-horizontal: 1rem;
  --general-radius: 10px;
  --general-maxwidth: 2000px;
}

@media (width >= 480px) {
  :root {
    --general-padding-top: 118px;
    --general-padding-horizontal: 2rem;
  }
}

@media (width >= 720px) {
  :root {
    --general-radius: 20px;
  }
}

@media (width >= 860px) {
  :root {
    --general-padding-top: 142px;
  }
}

@media (width >= 960px) {
  :root {
    --general-padding-top: 9.625rem;
    --general-padding-horizontal: 4rem;
    --general-radius: 30px;
  }
}

@media (width >= 1800px) {
  :root {
    --general-padding-horizontal: 6rem;
    --general-maxwidth: calc(1800px - 12rem);
  }
}

:root {
  --popover-shadow: 0px 0px 0px #3f39191a, 0px 1px 2px #3f39191a, 0px 3px 3px #3f391917, 0px 6px 4px #3f39190d, 0px 11px 4px #3f391903, 0px 17px 5px #3f391900;
}

.header {
  z-index: 1000;
  border-radius: 29px;
  justify-content: space-between;
  width: calc(100% - 2rem);
  max-width: 320px;
  padding: .75rem 1.5rem;
  display: flex;
  position: fixed;
  top: 1rem;
  left: 1rem;
  overflow: hidden;
}

@media (width >= 480px) {
  .header {
    top: 2rem;
    left: 2rem;
  }
}

@media (width >= 860px) {
  .header {
    border-radius: 50px;
    justify-content: flex-start;
    align-items: center;
    left: 1rem;
  }
}

@media (width >= 960px) {
  .header {
    left: calc(var(--general-padding-horizontal)  - 3rem);
    padding: .75rem 1.5rem .75rem 3rem;
  }
}

@media (width >= 1800px) {
  .header {
    left: calc(50% - var(--general-maxwidth) / 2 - 3rem);
    padding: .75rem 1.5rem .75rem 3rem;
  }
}

.header.is-open {
  height: 160px;
}

@media (width >= 860px) {
  .header.is-open {
    height: auto;
  }
}

.header.is-open .header-menu {
  opacity: 1;
}

@media (width >= 860px) {
  .header.expanded, .header:hover {
    width: calc(100% - 2rem);
    max-width: 1200px;
    padding: 1.5rem 1.5rem 1.5rem 2rem;
    transition-delay: 0s;
  }
}

@media (width >= 860px) and (width >= 860px) {
  .header.expanded, .header:hover {
    padding: 1.5rem;
  }
}

@media (width >= 860px) and (width >= 960px) {
  .header.expanded, .header:hover {
    width: 80vw;
    min-width: 940px;
    padding: 1.5rem 1.5rem 1.5rem 3rem;
  }
}

@media (width >= 860px) {
  .header.expanded .header-border, .header:hover .header-border {
    transition: box-shadow .8s cubic-bezier(.87, 0, .13, 1) !important;
    box-shadow: 0 0 #4a0024 !important;
  }

  .header.expanded .header-menu, .header:hover .header-menu {
    opacity: 1;
    transition: opacity .6s cubic-bezier(.87, 0, .13, 1) .4s;
  }
}

.header .logo {
  cursor: pointer;
  color: var(--text-primary);
  width: 190px;
  height: 35px;
  transition: height .8s cubic-bezier(.87, 0, .13, 1), color .2s;
  display: block;
  position: relative;
}

@media (width >= 960px) {
  .header .logo {
    width: 223px;
    height: 42px;
  }
}

@media (width >= 860px) {
  .header .burger {
    display: none;
  }
}

.header-border {
  pointer-events: none;
  background: var(--surface-secondary);
  box-shadow: 0 0 0 0px var(--text-primary);
  backdrop-filter: blur(15px);
  border-radius: 29px;
  position: absolute;
  inset: 0;
}

@media (width >= 860px) {
  .header-border {
    border-radius: 50px;
  }
}

.header-menu {
  opacity: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: calc(100% - 3rem);
  font-family: New Spirit;
  font-size: 22px;
  line-height: 100%;
  transition-delay: 0s;
  display: flex;
  position: absolute;
  top: 66px;
  left: 1.5rem;
}

@media (width >= 480px) {
  .header-menu {
    font-size: 22px;
  }
}

@media (width >= 860px) {
  .header-menu {
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 0;
    width: auto;
    transition: opacity .3s cubic-bezier(.65, 0, .35, 1);
    top: auto;
    left: auto;
    right: 1.5rem;
  }
}

@media (width >= 960px) {
  .header-menu {
    font-size: 26px;
  }
}

.header-menu-item, .header-menu-button {
  display: block;
}

.header-menu-item:after, .header-menu-button:after {
  content: " ";
  background-color: var(--navigation-active);
  opacity: 0;
  pointer-events: none;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  transition: opacity .2s, transform .2s;
  display: block;
  position: absolute;
  left: -8px;
  transform: scale(.5);
}

@media (width >= 860px) {
  .header-menu-item:after, .header-menu-button:after {
    width: 8px;
    height: 8px;
    left: -4px;
  }
}

.header-menu-item:hover, .header-menu-button:hover {
  color: var(--navigation-active);
  border-color: var(--navigation-active);
}

.header-menu-item.active, .header-menu-button.active {
  color: var(--navigation-active);
}

.header-menu-item.active:after, .header-menu-button.active:after {
  opacity: 1;
  transform: scale(1);
}

.header-menu-item, .header-menu-button, .header-menu-language {
  color: var(--text-primary);
  cursor: pointer;
  align-items: center;
  transition: color .2s;
  display: flex;
  position: relative;
}

@media (width >= 860px) {
  .header-menu-item {
    margin-right: 20px;
    padding: 9px 10px;
  }
}

.header-menu-button {
  word-break: nowrap;
  border: 1px solid var(--text-primary);
  border-radius: 30px;
  padding: 8px 16px;
  transition: border-color .2s, color .2s;
}

@media (width >= 860px) {
  .header-menu-button {
    padding: 8px 24px;
  }
}

.header-menu-button:hover {
  border-color: var(--miscellaneous-navigation-active);
}

.header-menu-item-contact, .header-menu-item-kontakt {
  border: 1px solid var(--text-primary);
  text-align: center;
  border-radius: 30px;
  flex-grow: 1;
  justify-content: center;
  width: 33%;
  padding: 8px 16px;
  transition: border-color .2s, color .2s;
}

@media (width >= 860px) {
  .header-menu-item-contact, .header-menu-item-kontakt {
    flex-grow: 0;
    width: auto;
    margin-right: 1rem;
    padding: 8px 24px;
  }
}

.header-menu-item-contact.active, .header-menu-item-kontakt.active {
  border: 1px solid #0000;
}

.header-menu-item-contact:after, .header-menu-item-kontakt:after {
  left: 1vw;
}

@media (width >= 350px) {
  .header-menu-item-contact:after, .header-menu-item-kontakt:after {
    left: 16px;
  }
}

@media (width >= 860px) {
  .header-menu-item-contact:after, .header-menu-item-kontakt:after {
    left: 6px;
  }
}

.header-menu-language {
  border: 1px solid var(--text-primary);
  border-radius: 30px;
  align-items: center;
  gap: 12px;
  padding: 3px 3px 3px 12px;
  display: flex;
}

.header-menu-language--de {
  padding: 3px 12px 3px 3px;
}

.header-menu-language .language-active {
  background-color: var(--text-primary);
  color: var(--surface-secondary);
  border-radius: 30px;
  padding: 5px 12px;
}

.button {
  font-family: var(--new-spirit);
  font-size: var(--button-large);
  color: var(--surface-primary);
  background-color: var(--text-primary);
  white-space: nowrap;
  cursor: pointer;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 100%;
  padding: 12px 20px;
  transition: background-color .2s, color .2s, border .2s;
  display: inline-flex;
  position: relative;
}

.button.button-icon-only {
  padding: 12px;
}

.button .button-text {
  vertical-align: middle;
  padding: 3px 0;
  line-height: 100%;
  display: inline-block;
}

.button svg {
  stroke-width: 1.5px;
  width: 24px;
  min-width: 24px;
  height: 24px;
}

.button:visited {
  color: var(--surface-primary);
}

.button:hover {
  background-color: var(--text-hover);
}

.button.secondary {
  color: var(--text-primary);
  border: 1px solid var(--text-primary);
  background-color: #0000;
}

.button.secondary:hover {
  color: var(--text-hover);
  border: 1px solid var(--text-hover);
  background-color: #0000;
}

.button-icon-left {
  flex-direction: row-reverse;
}

.button-size-small {
  font-size: var(--button-small);
  padding: 10px 16px;
}

.button-size-small.button-icon-only {
  padding: 10px;
}

.button-size-small .button-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 1px 0;
  overflow: hidden;
}

.button-size-small svg {
  stroke-width: 1.5px;
  width: 16px;
  min-width: 16px;
  height: 16px;
}

.template-home .hero {
  border-bottom: 1px solid var(--text-primary);
}

.hero {
  max-width: var(--general-maxwidth);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2rem;
  position: relative;
}

.hero .image-w-button {
  grid-column: span 12;
  width: 100%;
}

.hero .image-w-button .single-image, .hero .image-w-button img {
  object-fit: cover;
  height: 100%;
  min-height: 100%;
  max-height: 75svh;
}

@media (width >= 720px) {
  .hero-year {
    text-align: right;
  }
}

@media (width >= 960px) {
  .hero-year {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.hero-text {
  flex-direction: column;
  grid-column: span 12;
  gap: 1rem;
  height: 100%;
  display: flex;
  position: relative;
}

.hero-text p {
  max-width: 480px;
}

@media (width >= 720px) {
  .hero-text {
    flex-direction: row;
    align-items: flex-end;
    gap: 2rem;
  }
}

.hero-textwrapper {
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  height: 100%;
  display: flex;
  position: relative;
}

@media (width >= 720px) {
  .hero-portrait .video-autoplay-wrapper {
    min-height: 100%;
    max-height: calc(100svh - var(--general-padding-top)  - 2rem) !important;
  }

  .hero-portrait .image-w-button {
    grid-column: span 4;
  }

  .hero-portrait .hero-text {
    grid-column: span 8;
  }
}

.credits {
  width: 100%;
  margin: 2rem 0;
}

@media (width <= 719px) {
  .credits .grid {
    gap: 4rem;
  }

  .credits .column {
    text-align: center;
    align-items: center;
  }

  .credits .contactprofile {
    flex-direction: column;
    align-items: center;
  }
}

@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  text-shadow: none;
  z-index: 0;
  direction: ltr;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  max-width: 100%;
  transition: box-shadow .3s;
  display: flex;
  position: relative;
}

.plyr audio, .plyr iframe, .plyr video {
  width: 100%;
  height: 100%;
  display: block;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui :after, .plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: 2px;
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  padding: 3px 4px;
  line-height: 1;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  text-align: center;
  width: 100%;
  transition: transform .4s ease-in-out;
  animation: .3s plyr-fade-in;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.plyr__captions span:empty {
  display: none;
}

@media (width >= 480px) {
  .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: 20px;
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}

@media (width >= 768px) {
  .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(-40px);
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: #000c;
  background: var(--plyr-captions-background, #000c);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: var(--plyr-captions-text-color, #fff);
  white-space: pre-wrap;
  border-radius: 2px;
  padding: .2em .5em;
  line-height: 185%;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  border-radius: 4px;
  border-radius: var(--plyr-control-radius, 4px);
  color: inherit;
  cursor: pointer;
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
  background: none;
  border: 0;
  flex-shrink: 0;
  transition: all .3s;
  position: relative;
  overflow: visible;
}

.plyr__control svg {
  fill: currentColor;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: var(--plyr-control-icon-size, 18px);
  width: 18px;
  height: 18px;
  display: block;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control:focus-visible {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

.plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control.plyr__control--pressed .label--not-pressed, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed, a.plyr__control:after, a.plyr__control:before {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: 2.5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: 2.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 5px;
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}

.plyr [data-plyr="airplay"], .plyr [data-plyr="captions"], .plyr [data-plyr="fullscreen"], .plyr [data-plyr="pip"], .plyr__controls:empty {
  display: none;
}

.plyr--airplay-supported [data-plyr="airplay"], .plyr--captions-enabled [data-plyr="captions"], .plyr--fullscreen-enabled [data-plyr="fullscreen"], .plyr--pip-supported [data-plyr="pip"] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  background: #ffffffe6;
  background: var(--plyr-menu-background, #ffffffe6);
  border-radius: 8px;
  border-radius: var(--plyr-menu-radius, 8px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px #00000026);
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  text-align: left;
  white-space: nowrap;
  z-index: 3;
  margin-bottom: 10px;
  animation: .2s plyr-popup;
  position: absolute;
  bottom: 100%;
  right: -3px;
}

.plyr__menu__container > div {
  transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.plyr__menu__container:after {
  border: 4px solid #0000;
  border-top-color: #ffffffe6;
  border: var(--plyr-menu-arrow-size, 4px) solid #0000;
  border-top-color: var(--plyr-menu-background, #ffffffe6);
  content: "";
  right: 14px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7 - var(--plyr-menu-arrow-size, 4px) / 2);
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
}

.plyr__menu__container [role="menu"] {
  padding: 7px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container [role="menuitem"], .plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child, .plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  color: var(--plyr-menu-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: 4.66667px 10.5px;
  padding: calc(var(--plyr-control-spacing, 10px) * .7 / 1.5) calc(var(--plyr-control-spacing, 10px) * .7 * 1.5);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control:after {
  border: 4px solid #0000;
  border: var(--plyr-menu-item-arrow-size, 4px) solid #0000;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: 28px;
  padding-right: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: 6.5px;
  right: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--forward:focus-visible:after, .plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: initial;
}

.plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: 7px;
  margin: calc(var(--plyr-control-spacing, 10px) * .7);
  margin-bottom: 3.5px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  padding-left: 28px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 4);
  width: calc(100% - var(--plyr-control-spacing, 10px) * .7 * 2);
  width: calc(100% - 14px);
  position: relative;
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: 6.5px;
  left: calc(var(--plyr-control-spacing, 10px) * .7 * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--back:before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  margin-top: 3.5px;
  margin-top: calc(var(--plyr-control-spacing, 10px) * .7 / 2);
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.plyr__menu__container .plyr__control--back:focus-visible:after, .plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: initial;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: 7px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after, .plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  content: "";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  background: #0000001a;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  opacity: 0;
  background: #fff;
  border: 0;
  width: 6px;
  height: 6px;
  transition: transform .3s, opacity .3s;
  top: 50%;
  left: 12px;
  transform: translateY(-50%)scale(0);
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:before {
  background: #00b2ff;
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:after {
  opacity: 1;
  transform: translateY(-50%)scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:focus-visible:before, .plyr__menu__container .plyr__control[role="menuitemradio"]:hover:before {
  background: #23282f1a;
}

.plyr__menu__container .plyr__menu__value {
  margin-left: auto;
  margin-right: -5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) * .7 * -1 + 2px);
  padding-left: 24.5px;
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7 * 3.5);
  pointer-events: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.plyr--full-ui input[type="range"] {
  appearance: none;
  border-radius: 26px;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  height: calc(var(--plyr-range-thumb-active-shadow-width, 3px) * 2 + var(--plyr-range-thumb-height, 13px));
  background: none;
  border: 0;
  width: 100%;
  min-width: 0;
  height: 19px;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s;
  display: block;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(90deg, currentColor 0, #0000 0);
  background-image: linear-gradient(to right, currentColor var(--value, 0), #0000 var(--value, 0));
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  margin-top: -4px;
  margin-top: calc((var(--plyr-range-thumb-height, 13px)  - var(--plyr-range-track-height, 5px)) / 2 * -1);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  background: currentColor;
  height: 5px;
}

.plyr--full-ui input[type="range"]::-ms-track {
  color: #0000;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-track {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  border-radius: 2.5px;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: currentColor;
  border: 0;
  height: 5px;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  width: 13px;
  height: 13px;
  margin-top: 0;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"]:focus-visible::-webkit-slider-runnable-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-moz-range-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-ms-track {
  outline: 2px dashed #00b2ff;
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr__poster {
  background-color: #000;
  background-color: var(--plyr-video-background, var(--plyr-video-background, #000));
  opacity: 0;
  z-index: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
}

@media (width <= 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: #fff;
  background: var(--plyr-tooltip-background, #fff);
  border-radius: 5px;
  border-radius: var(--plyr-tooltip-radius, 5px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  padding: 5px 7.5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(var(--plyr-control-spacing, 10px) / 2 * 1.5);
  pointer-events: none;
  transform-origin: 50% 100%;
  white-space: nowrap;
  z-index: 2;
  line-height: 1.3;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 10px)scale(.8);
}

.plyr__tooltip:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-top: 4px solid #fff;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.plyr .plyr__control:focus-visible .plyr__tooltip, .plyr .plyr__control:hover .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%)scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  transform-origin: 0 100%;
  left: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: 16px;
  left: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  transform-origin: 100% 100%;
  left: auto;
  right: 0;
  transform: translateY(10px)scale(.8);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: 16px;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + var(--plyr-control-spacing, 10px) * .7);
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control:focus-visible .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child:focus-visible .plyr__tooltip, .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child:focus-visible .plyr__tooltip, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0)scale(1);
}

.plyr__progress {
  left: 6.5px;
  left: calc(var(--plyr-range-thumb-height, 13px) * .5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}

.plyr__progress input[type="range"], .plyr__progress__buffer {
  margin-left: -6.5px;
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  margin-right: -6.5px;
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
  width: calc(100% + 13px);
}

.plyr__progress input[type="range"] {
  z-index: 2;
  position: relative;
}

.plyr__progress .plyr__tooltip {
  overflow-wrap: break-word;
  max-width: 120px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  height: var(--plyr-range-track-height, 5px);
  margin-top: -2.5px;
  margin-top: calc(var(--plyr-range-track-height, 5px) / 2 * -1);
  background: none;
  border: 0;
  border-radius: 100px;
  height: 5px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: none;
}

.plyr__progress__buffer::-webkit-progress-value {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width .2s;
}

.plyr__progress__buffer::-moz-progress-bar {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  transition: width .2s;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s;
}

.plyr--loading .plyr__progress__buffer {
  background-image: linear-gradient(-45deg, #23282f99 25%, #0000 0 50%, #23282f99 0 75%, #0000 0, #0000);
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, #23282f99) 25%, #0000 25%, #0000 50%, var(--plyr-progress-loading-background, #23282f99) 50%, var(--plyr-progress-loading-background, #23282f99) 75%, #0000 75%, #0000);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: #0000;
  animation: 1s linear infinite plyr-progress;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: #ffffff40;
  background-color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr__progress__marker {
  background-color: #fff;
  background-color: var(--plyr-progress-marker-background, #fff);
  height: var(--plyr-range-track-height, 5px);
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
  border-radius: 1px;
  width: 3px;
  height: 5px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.plyr__volume {
  align-items: center;
  display: flex;
  position: relative;
}

.plyr__volume input[type="range"] {
  margin-left: 5px;
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: 5px;
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  z-index: 2;
  min-width: 60px;
  max-width: 90px;
  position: relative;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: #fff;
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: #c1c8d199;
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #23282f1a;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--audio .plyr__progress__buffer {
  color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr--video {
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: #000;
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  border-radius: inherit;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16 / 9;
}

@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.2813%);
}

.plyr--video .plyr__controls {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: var(--plyr-video-control-color, #fff);
  padding: 5px;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: 20px;
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  z-index: 3;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (width >= 480px) {
  .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: 35px;
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded="true"] {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: #00b2ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color, #fff);
  opacity: .9;
  padding: 15px;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  z-index: 2;
  border: 0;
  border-radius: 100%;
  transition: all .3s;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plyr__control--overlaid svg {
  position: relative;
  left: 2px;
}

.plyr__control--overlaid:focus, .plyr__control--overlaid:hover {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: #ffffff40;
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: 0 1px 1px #23282f26, 0 0 0 1px #23282f33, 0 0 0 3px #ffffff80;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--video .plyr__progress__buffer {
  color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr:fullscreen {
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (width >= 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  z-index: 10000000;
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
  inset: 0;
  border-radius: 0 !important;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (width >= 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  cursor: pointer;
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__ads > div, .plyr__ads > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.plyr__ads:after {
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  pointer-events: none;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
  background: #23282f;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  height: var(--plyr-range-track-height, 5px);
  opacity: .8;
  z-index: 3;
  background: currentColor;
  width: 3px;
  height: 5px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plyr__preview-thumb {
  background-color: #fff;
  background-color: var(--plyr-tooltip-background, #fff);
  border-radius: 8px;
  border-radius: var(--plyr-menu-radius, 8px);
  box-shadow: 0 1px 2px #00000026;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  margin-bottom: 10px;
  margin-bottom: calc(var(--plyr-control-spacing, 10px) / 2 * 2);
  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 100%;
  z-index: 2;
  padding: 3px;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  transform: translateY(10px)scale(.8);
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0)scale(1);
}

.plyr__preview-thumb:before {
  border-left: 4px solid #0000;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-right: 4px solid #0000;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid #0000;
  border-top: 4px solid #fff;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: -4px;
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  left: calc(50% + var(--preview-arrow-offset));
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  transform: translateX(-50%);
}

.plyr__preview-thumb__image-container {
  border-radius: 7px;
  border-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  z-index: 0;
  background: #c1c8d1;
  position: relative;
  overflow: hidden;
}

.plyr__preview-thumb__image-container img, .plyr__preview-thumb__image-container:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  box-shadow: inset 0 0 0 1px #00000026;
}

.plyr__preview-thumb__image-container img {
  max-width: none;
  max-height: none;
}

.plyr__preview-thumb__time-container {
  background: linear-gradient(#0000, #000000bf);
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: 7px;
  border-bottom-left-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  border-bottom-right-radius: 7px;
  border-bottom-right-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  z-index: 3;
  padding: 20px 6px 6px;
  line-height: 1.1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  filter: blur(1px);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  object-fit: contain;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
}

.plyr [hidden] {
  display: none !important;
}

.video-autoplay-wrapper {
  width: 100%;
  display: block;
  position: relative;
}

@media (width >= 720px) {
  .video-autoplay-wrapper.stretch {
    height: 100%;
  }

  .video-autoplay-wrapper.stretch .video-autoplay {
    object-fit: cover;
    height: 100%;
    min-height: 100px;
    display: block;
    position: absolute;
    inset: 0;
  }
}

.video-autoplay {
  border-radius: var(--general-radius);
  width: 100%;
  display: block;
  position: relative;
}

.custom-plyr-wrapper {
  width: 100%;
}

.plyr {
  --plyr-color-main: var(--text-primary);
  aspect-ratio: var(--aspect-ratio);
  border-radius: 20px;
  width: 100%;
}

.plyr .plyr__control--overlaid svg {
  color: #000;
}

.plyr.plyr--stopped video {
  opacity: 0;
}

.plyr--fullscreen-fallback {
  width: 100vw;
  min-height: 100svh;
  max-height: 100lvh;
}

.contactprofile {
  text-align: center;
  background-color: var(--surface-secondary);
  border-radius: var(--general-radius);
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 1rem;
  display: flex;
}

@container (width >= 360px) {
  .contactprofile {
    text-align: left;
    flex-flow: wrap;
    align-items: flex-start;
    padding: 2rem;
  }
}

.contactprofile-image img {
  object-fit: cover;
  border-radius: 50%;
  width: 96px;
  height: 96px;
}

.contactprofile-info {
  flex-direction: column;
  gap: 12px;
  width: 100%;
  display: flex;
}

@container (width >= 360px) {
  .contactprofile-info {
    width: fit-content;
  }
}

.contactprofile-buttons {
  --text-hover: var(--navigation-active);
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
}

@container (width >= 360px) {
  .contactprofile-buttons {
    align-items: flex-start;
  }
}

.founder-video {
  width: 100%;
  margin: 0 auto;
  position: relative;
}

@media (width >= 960px) {
  .founder-video {
    max-width: 820px;
  }
}

@media (width >= 1800px) {
  .founder-video {
    max-width: 1200px;
  }
}

.founder-video-wrapper {
  position: relative;
  overflow: hidden;
}

.founder-video-wrapper:after {
  content: " ";
  z-index: 50;
  background-color: var(--yellow);
  border-radius: 20px;
  width: 100%;
  padding-bottom: 56.25%;
  transition: opacity .3s, transform .3s;
  display: block;
  position: absolute;
  inset: auto 0 0;
}

.founder-video-wrapper.cover-hidden .founder-video-portrait {
  pointer-events: none;
  opacity: 0;
  transform: translate3d(0, 10%, 0);
}

.founder-video-wrapper.cover-hidden .founder-video-button {
  pointer-events: none;
  opacity: 0;
  bottom: 16px;
}

.founder-video-wrapper.cover-hidden:after {
  pointer-events: none;
  opacity: 0;
  transform: translate3d(0, -10%, 0);
}

.founder-video-wrapper.cover-hidden .plyr {
  transform: translate3d(0, -10%, 0);
}

.founder-video-topline {
  text-align: center;
  margin-bottom: 8px;
}

.founder-video-headline {
  text-align: center;
  margin-bottom: 32px;
}

.founder-video-portrait {
  z-index: 100;
  transition: opacity .3s, transform .3s;
  position: absolute;
  inset: auto 0 0;
}

.founder-video-text {
  justify-content: space-between;
  margin-top: 32px;
  display: flex;
}

.founder-video-text-right {
  text-align: right;
}

.founder-video .plyr {
  border-radius: 20px;
  margin-top: 12%;
  transition: opacity .3s, transform .3s;
}

.founder-video-button.button {
  z-index: 200;
  margin: 0 auto;
  transition: opacity .3s, bottom .3s;
  position: absolute;
  bottom: 48px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

@media (width >= 720px) {
  .footer-spacer {
    max-height: 980px;
  }
}

.footer {
  --surface-primary: var(--white);
  --text-primary: var(--black);
  --text-hover: var(--orange);
  z-index: 0;
  color: var(--black);
  padding: 2rem 16px 16px;
  padding-left: var(--general-padding-horizontal);
  padding-right: var(--general-padding-horizontal);
  text-align: center;
  background-color: var(--white);
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  display: flex;
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
}

.footer:before {
  content: " ";
  pointer-events: none;
  background-color: var(--white);
  height: 600px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-99%);
}

@media (width >= 480px) {
  .footer {
    text-align: left;
  }
}

.footer-container {
  max-width: var(--general-maxwidth);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.footer-cta {
  flex-flow: column;
  align-items: stretch;
  gap: 1rem;
  width: 100%;
  max-width: 600px;
  margin-bottom: 2rem;
  display: flex;
}

@media (width >= 480px) {
  .footer-cta {
    flex-flow: wrap;
    align-items: flex-start;
    margin-bottom: 4rem;
  }

  .footer-cta p {
    margin: 1rem 0;
  }
}

.footer-columns {
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

@media (width >= 480px) {
  .footer-columns {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 4rem;
  }
}

.footer-columns .footer-column1 {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.footer-columns .footer-column2 {
  text-align: center;
}

.footer-columns .footer-column3 {
  flex-direction: column;
  align-items: center;
  gap: 12px;
  display: flex;
}

@media (width >= 480px) {
  .footer-columns .footer-column3 {
    align-items: flex-end;
  }
}

.footer-columns .footer-socials {
  gap: 12px;
  display: flex;
}

.footer .footer-links {
  gap: 16px;
  display: flex;
}

.footer .footer-links a {
  text-underline-offset: 2px;
  white-space: nowrap;
  text-decoration: underline;
}

.footer .logo-wall {
  margin: 1rem 0;
}

.footer .logo-wall.logowall-one-row {
  gap: 2rem;
}

@media (width >= 720px) {
  .footer .logo-wall.logowall-one-row {
    gap: 3rem;
  }
}

.footer .logo-wall.logowall-one-row .logo-item {
  width: auto;
  height: 40px;
}

@media (width >= 720px) {
  .footer .logo-wall.logowall-one-row .logo-item {
    height: 60px;
  }
}

.footer .logo-wall.logowall-one-row .logo-item img {
  width: auto;
  height: 100%;
}

.single-image {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.single-image picture {
  width: 100%;
  display: flex;
}

.single-image img {
  border-radius: var(--general-radius);
}

@media (width >= 720px) {
  .single-image.stretch {
    height: 100%;
    min-height: 100px;
    display: block;
    position: relative;
  }

  .single-image.stretch img {
    object-fit: cover;
    height: 100%;
    position: absolute;
    inset: 0;
  }
}

.single-image.has-mobile .desktop-image {
  display: none;
}

@media (width >= 720px) {
  .single-image.has-mobile .desktop-image {
    display: block;
  }

  .single-image.has-mobile .mobile-image {
    display: none;
  }
}

.image-w-button {
  --radius: 20px;
  width: 100%;
  display: block;
  position: relative;
}

.image-w-button.has-default-button {
  --radius: 30px;
}

@media (width <= 960px) {
  .image-w-button, .image-w-button.has-default-button {
    --radius: 20px;
  }
}

.image-w-button .single-image, .image-w-button .video-autoplay-wrapper {
  border-radius: var(--radius);
  width: 100%;
  display: block;
  position: relative;
  overflow: clip;
}

.image-w-button img {
  display: block;
  position: relative;
}

@media (width <= 960px) {
  .image-w-button .button {
    font-size: var(--button-small);
    padding: 8px 16px;
  }

  .image-w-button .button.button-icon-only {
    padding: 8px;
  }

  .image-w-button .button .button-text {
    padding: 1px 0;
  }

  .image-w-button .button svg {
    stroke-width: 1.5px;
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
}

.notch {
  z-index: 10;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.notch:before, .notch .notch-row:after, .notch.notch--expandable .notch-row:first-of-type:before {
  content: "";
  width: var(--radius);
  height: var(--radius);
  background-color: var(--surface-primary);
  box-shadow: 0px 0px 0px 1px var(--surface-primary);
  display: inline-block;
  position: absolute;
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='notch-tile' viewBox='0 0 20 20'%3E%3Cpath d='M0 20H20C8.95435 20 0 11.0457 0 0V20Z' fill='currentColor' /%3E%3C/svg%3E");
}

.notch:before {
  left: 0;
  top: calc(var(--radius) * -1);
}

.notch-row {
  min-height: calc(var(--radius) * 2);
  max-width: calc(100% - var(--radius) * 2);
  border-top-right-radius: var(--radius);
  background: var(--surface-primary);
  box-shadow: 0px 0px 0px .5px var(--surface-primary);
  align-items: flex-end;
  width: auto;
  padding-top: 10px;
  padding-bottom: 1px;
  padding-right: 10px;
  display: flex;
  position: relative;
}

.notch-row:after {
  top: auto;
  left: auto;
  right: calc(var(--radius) * -1);
  bottom: 0;
}

.notch--top-right {
  --radius: 20px;
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--radius);
  align-items: flex-end;
  width: auto;
  inset: 0 0 auto auto;
}

.notch--top-right:before, .notch--top-right .notch-row:after {
  mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='notch-tile' viewBox='0 0 20 20'%3E%3Cpath d='M0 20H20C8.95435 20 0 11.0457 0 0V20Z' fill='currentColor' transform='rotate(180, 10, 10)' /%3E%3C/svg%3E");
}

.notch--top-right:before {
  top: auto;
  left: auto;
  bottom: calc(var(--radius) * -1);
  right: 0;
}

.notch--top-right .notch-row {
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--radius);
  max-width: none;
  padding: 0 0 10px 10px;
}

.notch--top-right .notch-row:after {
  left: calc(var(--radius) * -1);
  top: 0;
  bottom: auto;
  right: auto;
}

.notch--expandable:before {
  display: none;
}

.notch--expandable .notch-row:first-of-type {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
}

.notch--expandable .notch-row:first-of-type:before {
  left: 0;
  top: calc(var(--radius) * -1);
}

.notch--expandable h3 {
  font-size: 18px;
}

@container (width >= 320px) {
  .notch--expandable h3 {
    font-size: 22px;
  }
}

@container (width >= 440px) {
  .notch--expandable h3 {
    font-size: 24px;
  }
}

.notch h1, .notch h2, .notch h3, .notch h4, .notch p {
  color: var(--text-primary);
  margin: 0;
  line-height: 100%;
  display: inline-block;
}

.quote {
  border: 1px solid var(--dark-grey);
  background-color: var(--off-white);
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  height: 100%;
  padding: 16px;
  display: flex;
}

.quote-main {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
}

.quote footer {
  flex-direction: column;
  display: flex;
}

.quote-profile {
  align-items: center;
  gap: 16px;
  display: flex;
}

.quote-logo {
  width: auto;
  height: 24px;
}

.quote-citation {
  align-items: center;
  gap: 6px;
  display: flex;
}

@media (width >= 720px) {
  .quote-citation {
    gap: 8px;
  }
}

.quote-image {
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

ul {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  display: block;
}

ul li {
  border-bottom: 1px solid var(--text-primary);
  padding: 8px 0;
  list-style: none;
}

ul li:last-of-type {
  border-bottom: none;
}

.awardlist {
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  display: block;
}

.awardlist li {
  border-bottom: 1px solid var(--text-primary);
  align-items: center;
  gap: 12px;
  padding: 0;
  list-style: none;
  display: flex;
}

.awardlist li .awardlogo {
  background-color: var(--surface-primary);
}

.awardlist li .awardlogo img {
  mix-blend-mode: multiply;
  filter: grayscale();
  object-fit: cover;
  width: 48px;
  height: 48px;
}

.awardlist li:last-of-type {
  border-bottom: none;
}

.line {
  border: 1px solid #0000;
  border-top: 1px solid var(--text-primary);
  width: 100%;
  margin: 0;
}

@media (width >= 720px) {
  .line {
    margin: 2rem 0;
  }
}

.iconlabel {
  align-items: center;
  gap: 12px;
  display: flex;
}

.iconlabel-icon {
  background-color: var(--grey);
  border-radius: 8px;
  flex-shrink: 0;
  padding: 12px;
}

.iconlabel-icon svg {
  width: 24px;
  height: 24px;
  display: block;
}

.textcard {
  text-align: left;
  border: 1px solid var(--dark-grey);
  border-radius: 20px;
  min-width: 284px;
  padding: 16px 24px 16px 16px;
  position: relative;
}

@media (width >= 960px) {
  .textcard {
    border-color: #0000;
    gap: 10px;
    min-width: 100%;
    padding: 0;
    display: flex;
  }
}

.textcard .textcard-marker {
  margin-top: calc(var(--copy-text) / 2);
  background-color: #d0c5fb;
  border-radius: 50%;
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  display: none;
}

@media (width >= 960px) {
  .textcard .textcard-marker {
    display: block;
  }
}

.textcard svg {
  color: var(--dark-grey);
  position: absolute;
  bottom: 8px;
  right: 8px;
}

@media (width >= 960px) {
  .textcard svg {
    display: none;
  }
}

.textcard-wrapper {
  gap: 16px;
  width: 100vw;
  margin-left: -16px;
  padding: 0 16px 32px;
  display: flex;
  overflow: scroll hidden;
}

@media (width >= 720px) {
  .textcard-wrapper {
    margin-left: -32px;
    padding: 0 32px 32px;
  }
}

@media (width >= 960px) {
  .textcard-wrapper {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    padding: 0;
    overflow: hidden;
  }
}

.textcard-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.textcard-wrapper::-webkit-scrollbar {
  display: none;
}

.card {
  background: var(--surface-secondary);
  border-radius: 30px;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding: 2rem;
  display: flex;
}

@container (width >= 640px) {
  .card {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.card-wrapper {
  flex-direction: column;
  gap: 2rem;
  display: flex;
}

.features {
  width: 100%;
}

@media (width >= 960px) {
  .features {
    align-items: flex-start;
    gap: 32px;
    margin-top: 32px;
    display: flex;
  }
}

.features #ipad {
  filter: drop-shadow(0 8px 10px #bcbcbc) drop-shadow(0 16px 20px #bcbcbc);
  z-index: -1;
  position: relative;
}

.feature {
  border: 1px solid var(--dark-grey);
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  gap: 12px;
  min-width: 260px;
  padding: 6px;
  transition: border .3s, background-color .3s;
  display: flex;
}

@media (width >= 960px) {
  .feature {
    width: calc(50% - 8px);
    min-width: 0;
  }
}

.feature-icon {
  background-color: var(--grey);
  border-radius: 8px;
  flex-shrink: 0;
  padding: 12px;
  transition: background-color .3s, color .3s;
}

.feature-icon svg {
  width: 24px;
  height: 24px;
  transition: color .15s;
  display: block;
}

.feature.active {
  background-color: var(--violet-light);
}

.feature.active .feature-icon {
  color: #fff;
  background-color: #000;
}

.features-list {
  gap: 16px;
  width: 100vw;
  margin-bottom: 32px;
  margin-left: -16px;
  padding: 0 16px;
  display: flex;
  overflow-x: scroll;
}

@media (width >= 720px) {
  .features-list {
    margin-left: -32px;
    padding: 0 32px;
  }
}

@media (width >= 960px) {
  .features-list {
    flex-direction: column;
    width: 100%;
    margin-left: 0;
    padding: 0;
  }
}

.features-showcase {
  width: 100%;
}

.feature-wrapper {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@media (width >= 960px) {
  .feature-wrapper {
    flex-direction: row;
  }
}

.features-caption {
  text-align: center;
  min-height: 48px;
  margin-top: 16px;
}

.features-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.features-list::-webkit-scrollbar {
  display: none;
}

.contact-headline {
  text-align: center;
  width: 100%;
  margin-top: 64px;
  padding: 0 16px;
}

.contact-error {
  text-align: center;
  color: #fff;
  background-color: red;
  border-radius: 10px;
  width: 100%;
  padding: 16px;
}

.alert.error {
  color: #fff;
  text-align: center;
  background-color: red;
  border-radius: 0 8px 8px;
  margin-top: 12px;
  margin-left: 48px;
  padding: 12px;
  font-size: 12px;
  position: absolute;
  top: 100%;
  left: 0;
}

.contact-success {
  margin-bottom: 64px;
  display: none;
}

.contactform {
  border-radius: var(--general-radius);
  background-color: var(--off-white);
  border: 1px solid var(--text-disabled);
  flex-direction: column;
  align-items: flex-end;
  gap: 64px;
  width: 100%;
  margin: 0 auto;
  padding: 32px 16px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (width >= 720px) {
  .contactform {
    padding: 64px 32px;
  }
}

.contactform-intro {
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  display: flex;
}

.contactform-intro p, .contactform-intro h4 {
  max-width: 380px;
}

.contactform .fields-wrapper {
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  width: 100%;
  display: grid;
}

.contactform .field {
  background-color: var(--off-white);
  font-size: var(--copy-text);
  font-family: Labil Grotesk, Helvetica, sans-serif;
  position: relative;
}

.contactform .field--message {
  grid-column: span 2;
}

.contactform .field label {
  display: none;
}

.contactform .field-bottom {
  grid-column: span 2;
}

@media (width >= 960px) {
  .contactform .field-bottom {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.contactform .field-bottom label {
  font-size: var(--copy-large);
  display: block;
  position: relative;
}

.contactform .field input, .contactform .field textarea {
  color: var(--text-primary);
  font-size: 18px;
  font-family: var(--bw-gradual);
  border: 0 solid #0000;
  border-bottom: 1px solid var(--text-primary);
  background-color: #0000;
  outline: none;
  width: 100%;
  padding: 8px 0;
  border-radius: 0 !important;
}

.contactform .field input::placeholder, .contactform .field textarea::placeholder {
  color: var(--text-primary);
  opacity: .6;
}

.contactform .field textarea {
  resize: none;
  min-height: 100px;
  max-height: 50vh;
  margin-bottom: 16px;
}

.contactform .button {
  border: none;
  outline: none;
  width: 100%;
}

@media (width >= 960px) {
  .contactform .button {
    flex-shrink: 0;
    width: auto;
  }
}

.contactform .honeypot {
  position: absolute;
  left: -9999px;
}

.contactinfo-wrapper {
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  width: 100%;
  margin: 64px auto;
  display: flex;
}

@media (width >= 480px) {
  .contactinfo-wrapper {
    flex-direction: row;
    align-items: flex-end;
  }
}

@media (width >= 960px) {
  .contactinfo-wrapper {
    max-width: 820px;
  }
}

@media (width >= 1800px) {
  .contactinfo-wrapper {
    max-width: 1200px;
  }
}

.contactinfo {
  flex-direction: column;
  gap: 16px;
  width: 100%;
  display: flex;
}

.contactform label.checkbox-container {
  font-family: var(--bw-gradual);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  width: 100%;
  height: 32px;
  margin-bottom: 16px;
  padding-left: 48px;
  font-size: 18px;
  line-height: 1;
  display: flex;
  position: relative;
  left: 0;
  transform: none;
}

.contactform label.checkbox-container p {
  font-size: 14px;
}

.contactform label.checkbox-container a {
  text-decoration: underline;
}

@media (width >= 960px) {
  .contactform label.checkbox-container {
    max-width: 800px;
    margin-bottom: 0;
    padding-right: 16px;
  }
}

.checkbox-container input {
  opacity: 0;
  cursor: pointer;
  position: absolute;
  width: 0 !important;
  height: 0 !important;
}

.checkmark {
  border: 2px solid var(--text-primary);
  cursor: pointer;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: var(--white);
}

.checkbox-container input:checked ~ .checkmark {
  background-color: var(--text-primary);
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  width: 8px;
  height: 14px;
  top: 5px;
  left: 9px;
  transform: rotate(45deg);
}

.storytelling {
  grid-template-columns: 1fr;
  width: 100%;
  display: grid;
  position: relative;
}

@media (width >= 720px) {
  .storytelling {
    grid-template-columns: 1fr 1fr;
  }
}

.storytelling .lottie-container:first-child:nth-child(odd), .storytelling .storytelling-section:first-child:nth-child(odd) {
  grid-row: 2;
}

@media (width >= 720px) {
  .storytelling .lottie-container:first-child:nth-child(odd), .storytelling .storytelling-section:first-child:nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:first-child:nth-child(2n), .storytelling .storytelling-section:first-child:nth-child(2n) {
  grid-row: 0;
}

@media (width >= 720px) {
  .storytelling .lottie-container:first-child:nth-child(2n), .storytelling .storytelling-section:first-child:nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(2):nth-child(odd), .storytelling .storytelling-section:nth-child(2):nth-child(odd) {
  grid-row: 3;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(2):nth-child(odd), .storytelling .storytelling-section:nth-child(2):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(2):nth-child(2n), .storytelling .storytelling-section:nth-child(2):nth-child(2n) {
  grid-row: 1;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(2):nth-child(2n), .storytelling .storytelling-section:nth-child(2):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(3):nth-child(odd), .storytelling .storytelling-section:nth-child(3):nth-child(odd) {
  grid-row: 4;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(3):nth-child(odd), .storytelling .storytelling-section:nth-child(3):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(3):nth-child(2n), .storytelling .storytelling-section:nth-child(3):nth-child(2n) {
  grid-row: 2;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(3):nth-child(2n), .storytelling .storytelling-section:nth-child(3):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(4):nth-child(odd), .storytelling .storytelling-section:nth-child(4):nth-child(odd) {
  grid-row: 5;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(4):nth-child(odd), .storytelling .storytelling-section:nth-child(4):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(4):nth-child(2n), .storytelling .storytelling-section:nth-child(4):nth-child(2n) {
  grid-row: 3;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(4):nth-child(2n), .storytelling .storytelling-section:nth-child(4):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(5):nth-child(odd), .storytelling .storytelling-section:nth-child(5):nth-child(odd) {
  grid-row: 6;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(5):nth-child(odd), .storytelling .storytelling-section:nth-child(5):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(5):nth-child(2n), .storytelling .storytelling-section:nth-child(5):nth-child(2n) {
  grid-row: 4;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(5):nth-child(2n), .storytelling .storytelling-section:nth-child(5):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(6):nth-child(odd), .storytelling .storytelling-section:nth-child(6):nth-child(odd) {
  grid-row: 7;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(6):nth-child(odd), .storytelling .storytelling-section:nth-child(6):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(6):nth-child(2n), .storytelling .storytelling-section:nth-child(6):nth-child(2n) {
  grid-row: 5;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(6):nth-child(2n), .storytelling .storytelling-section:nth-child(6):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(7):nth-child(odd), .storytelling .storytelling-section:nth-child(7):nth-child(odd) {
  grid-row: 8;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(7):nth-child(odd), .storytelling .storytelling-section:nth-child(7):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(7):nth-child(2n), .storytelling .storytelling-section:nth-child(7):nth-child(2n) {
  grid-row: 6;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(7):nth-child(2n), .storytelling .storytelling-section:nth-child(7):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(8):nth-child(odd), .storytelling .storytelling-section:nth-child(8):nth-child(odd) {
  grid-row: 9;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(8):nth-child(odd), .storytelling .storytelling-section:nth-child(8):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(8):nth-child(2n), .storytelling .storytelling-section:nth-child(8):nth-child(2n) {
  grid-row: 7;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(8):nth-child(2n), .storytelling .storytelling-section:nth-child(8):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(9):nth-child(odd), .storytelling .storytelling-section:nth-child(9):nth-child(odd) {
  grid-row: 10;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(9):nth-child(odd), .storytelling .storytelling-section:nth-child(9):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(9):nth-child(2n), .storytelling .storytelling-section:nth-child(9):nth-child(2n) {
  grid-row: 8;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(9):nth-child(2n), .storytelling .storytelling-section:nth-child(9):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(10):nth-child(odd), .storytelling .storytelling-section:nth-child(10):nth-child(odd) {
  grid-row: 11;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(10):nth-child(odd), .storytelling .storytelling-section:nth-child(10):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(10):nth-child(2n), .storytelling .storytelling-section:nth-child(10):nth-child(2n) {
  grid-row: 9;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(10):nth-child(2n), .storytelling .storytelling-section:nth-child(10):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(11):nth-child(odd), .storytelling .storytelling-section:nth-child(11):nth-child(odd) {
  grid-row: 12;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(11):nth-child(odd), .storytelling .storytelling-section:nth-child(11):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(11):nth-child(2n), .storytelling .storytelling-section:nth-child(11):nth-child(2n) {
  grid-row: 10;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(11):nth-child(2n), .storytelling .storytelling-section:nth-child(11):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(12):nth-child(odd), .storytelling .storytelling-section:nth-child(12):nth-child(odd) {
  grid-row: 13;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(12):nth-child(odd), .storytelling .storytelling-section:nth-child(12):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(12):nth-child(2n), .storytelling .storytelling-section:nth-child(12):nth-child(2n) {
  grid-row: 11;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(12):nth-child(2n), .storytelling .storytelling-section:nth-child(12):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(13):nth-child(odd), .storytelling .storytelling-section:nth-child(13):nth-child(odd) {
  grid-row: 14;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(13):nth-child(odd), .storytelling .storytelling-section:nth-child(13):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(13):nth-child(2n), .storytelling .storytelling-section:nth-child(13):nth-child(2n) {
  grid-row: 12;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(13):nth-child(2n), .storytelling .storytelling-section:nth-child(13):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(14):nth-child(odd), .storytelling .storytelling-section:nth-child(14):nth-child(odd) {
  grid-row: 15;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(14):nth-child(odd), .storytelling .storytelling-section:nth-child(14):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(14):nth-child(2n), .storytelling .storytelling-section:nth-child(14):nth-child(2n) {
  grid-row: 13;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(14):nth-child(2n), .storytelling .storytelling-section:nth-child(14):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(15):nth-child(odd), .storytelling .storytelling-section:nth-child(15):nth-child(odd) {
  grid-row: 16;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(15):nth-child(odd), .storytelling .storytelling-section:nth-child(15):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(15):nth-child(2n), .storytelling .storytelling-section:nth-child(15):nth-child(2n) {
  grid-row: 14;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(15):nth-child(2n), .storytelling .storytelling-section:nth-child(15):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(16):nth-child(odd), .storytelling .storytelling-section:nth-child(16):nth-child(odd) {
  grid-row: 17;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(16):nth-child(odd), .storytelling .storytelling-section:nth-child(16):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(16):nth-child(2n), .storytelling .storytelling-section:nth-child(16):nth-child(2n) {
  grid-row: 15;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(16):nth-child(2n), .storytelling .storytelling-section:nth-child(16):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(17):nth-child(odd), .storytelling .storytelling-section:nth-child(17):nth-child(odd) {
  grid-row: 18;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(17):nth-child(odd), .storytelling .storytelling-section:nth-child(17):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(17):nth-child(2n), .storytelling .storytelling-section:nth-child(17):nth-child(2n) {
  grid-row: 16;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(17):nth-child(2n), .storytelling .storytelling-section:nth-child(17):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(18):nth-child(odd), .storytelling .storytelling-section:nth-child(18):nth-child(odd) {
  grid-row: 19;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(18):nth-child(odd), .storytelling .storytelling-section:nth-child(18):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(18):nth-child(2n), .storytelling .storytelling-section:nth-child(18):nth-child(2n) {
  grid-row: 17;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(18):nth-child(2n), .storytelling .storytelling-section:nth-child(18):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(19):nth-child(odd), .storytelling .storytelling-section:nth-child(19):nth-child(odd) {
  grid-row: 20;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(19):nth-child(odd), .storytelling .storytelling-section:nth-child(19):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(19):nth-child(2n), .storytelling .storytelling-section:nth-child(19):nth-child(2n) {
  grid-row: 18;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(19):nth-child(2n), .storytelling .storytelling-section:nth-child(19):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(20):nth-child(odd), .storytelling .storytelling-section:nth-child(20):nth-child(odd) {
  grid-row: 21;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(20):nth-child(odd), .storytelling .storytelling-section:nth-child(20):nth-child(odd) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container:nth-child(20):nth-child(2n), .storytelling .storytelling-section:nth-child(20):nth-child(2n) {
  grid-row: 19;
}

@media (width >= 720px) {
  .storytelling .lottie-container:nth-child(20):nth-child(2n), .storytelling .storytelling-section:nth-child(20):nth-child(2n) {
    order: initial;
    grid-row: initial;
  }
}

.storytelling .lottie-container {
  opacity: 1;
  background-color: var(--white);
  width: 100%;
  max-height: 50lvh;
  transition: opacity .3s;
}

@media (width >= 720px) {
  .storytelling .lottie-container {
    opacity: 0;
    height: 100vh;
    max-height: none;
    padding: 10lvh 0;
    position: sticky;
    top: 0;
  }

  .storytelling .lottie-container:nth-child(2) {
    opacity: 1;
  }
}

.storytelling-section {
  scroll-snap-align: start;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  margin-bottom: 128px;
  display: flex;
}

@media (width >= 720px) {
  .storytelling-section {
    min-height: 100vh;
    margin-bottom: 0;
  }
}

.storytelling-counter {
  align-items: center;
  display: flex;
}

.storytelling-counter .counter-text {
  margin-right: 8px;
}

.storytelling-counter .counter-circle {
  background-color: var(--grey);
  width: 24px;
  height: 12px;
}

.storytelling-counter .counter-circle:nth-child(2) {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.storytelling-counter .counter-circle:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.storytelling-counter .counter-circle.is-active {
  background-color: var(--violet-light);
}

.storytelling-footer {
  display: none;
}

@media (width >= 720px) {
  .storytelling-footer {
    align-items: center;
    gap: 12px;
    height: 0;
    display: flex;
    position: sticky;
    bottom: 56px;
  }
}

.chartadervielfalt {
  flex-direction: column;
  flex-shrink: 0;
  gap: 16px;
  width: 180px;
  display: flex;
}

@media (width >= 480px) {
  .chartadervielfalt {
    align-items: flex-end;
  }
}

@media (width >= 960px) {
  .chartadervielfalt {
    max-width: 820px;
  }
}

@media (width >= 1800px) {
  .chartadervielfalt {
    max-width: 1200px;
  }
}

.chartadervielfalt-image {
  width: 180px;
}

.work-filter {
  margin: var(--global-padding);
  margin-bottom: 0;
  padding-top: 4rem;
  position: relative;
}

@media (width >= 720px) {
  .work-filter {
    grid-template-columns: repeat(2, 1fr);
  }
}

.work-filter-resultnote {
  margin-top: calc(var(--global-padding) * 1);
}

.work-filter.singlename-active .project-filter-singlename {
  display: flex;
}

.work-filter.singlename-active .project-filter-services, .work-filter.singlename-active .project-filter-names {
  pointer-events: none;
  opacity: .4;
}

.work-filter-tags .info {
  display: flex;
}

.work-filter-tags .info.hidden {
  opacity: 0;
  pointer-events: none;
}

.work-filter-tags .info .label {
  text-transform: uppercase;
  margin-right: 1rem;
}

.work-filter-tags .info .reset {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  padding: 0 .5rem;
}

.work-filter-tags .options {
  margin-top: calc(var(--global-padding) / 2);
  min-height: calc(var(--global-padding) * 2);
  flex-wrap: wrap;
  flex-shrink: 0;
  gap: .5rem;
  width: 100%;
  display: flex;
}

@media (width >= 720px) {
  .work-filter-tags .options {
    gap: 1rem;
  }
}

.work-filter-tags .options .button {
  cursor: pointer;
  outline: inherit;
  font-family: var(--new-spirit);
  font-size: var(--button-large);
  padding: 8px 16px;
  line-height: 1;
}

.work-filter-tags .options .button.active {
  color: var(--surface-primary);
  background-color: var(--text-primary);
}

.work-filter-tags .options .button.active:hover {
  border-color: var(--text-primary);
}

.gradient:before {
  content: " ";
  background: radial-gradient(circle, var(--surface-gradient-highlight) 0%, var(--surface-primary) 70%, var(--surface-primary) 100%);
  opacity: .8;
  z-index: -1;
  border-radius: 50%;
  width: 100vw;
  max-width: 2000px;
  height: 100vw;
  max-height: 2000px;
  display: block;
  position: absolute;
}

.gradient-top-right:before {
  top: 0;
  right: 0;
  transform: translate3d(50%, -50%, 0);
}

.gradient-top-left:before {
  top: 0;
  left: 0;
  transform: translate3d(-50%, -50%, 0);
}

.gradient-bottom-left:before {
  bottom: 0;
  left: 0;
  transform: translate3d(-50%, 50%, 0);
}

.gradient-bottom-right:before {
  bottom: 0;
  right: 0;
  transform: translate3d(50%, 50%, 0);
}

.gradient-center:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.logo-wall {
  place-content: center;
  gap: 1rem;
  width: 100%;
  display: grid;
}

.logo-wall.logowall-one-row {
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

@media (width >= 720px) {
  .logo-wall.logowall-one-row {
    flex-wrap: nowrap;
  }
}

.logo-wall.logowall-one-row .logo-item {
  width: 80px;
  min-width: 80px;
}

@media (width >= 720px) {
  .logo-wall.logowall-one-row .logo-item {
    width: auto;
  }
}

.logo-item {
  text-align: center;
  width: 100%;
}

.logo-item img, .logo-item svg {
  object-fit: contain;
  max-width: 100%;
  height: auto;
  max-height: 148px;
  display: block;
}

@media (width >= 1200px) {
  .logo-wall {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (width <= 1199px) and (width >= 768px) {
  .logo-wall {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width <= 767px) {
  .logo-wall {
    grid-template-columns: repeat(2, 1fr);
  }
}

.burger {
  cursor: pointer;
  height: 35px;
}

.burger-icon {
  box-sizing: border-box;
  width: 32px;
  height: 20px;
  margin: 8px 0 8px 10px;
  position: relative;
}

.burger-icon span, .burger-icon:before, .burger-icon:after {
  content: " ";
  background-color: var(--text-primary);
  transform-origin: center;
  border-radius: 2px;
  width: 100%;
  height: 3px;
  transition: all .2s;
  display: block;
  position: absolute;
}

.burger-icon span {
  top: 50%;
  transform: translateY(-50%);
}

.burger-icon:before {
  top: 0;
  bottom: auto;
}

.burger-icon:after {
  top: auto;
  bottom: 0;
}

.burger-icon.is-active:before {
  top: 50%;
  transform: translateY(-50%)rotate(-45deg);
}

.burger-icon.is-active span {
  opacity: 0;
  transform: translateX(-10%);
}

.burger-icon.is-active:after {
  bottom: 50%;
  transform: translateY(50%)rotate(45deg);
}

.accordion {
  width: 100%;
  overflow: hidden;
}

.accordion .accordion-item {
  background-color: var(--surface-secondary);
  border-radius: var(--general-radius);
  width: 100%;
  margin-bottom: 1rem;
  padding: 2rem;
  display: flex;
}

.accordion .accordion-item:last-child {
  margin-bottom: 0;
}

.accordion .accordion-item.closed .accordion-icon-closed {
  display: block;
}

.accordion .accordion-item.closed .accordion-icon-open, .accordion .accordion-item.closed .accordion-content, .accordion .accordion-item.open .accordion-icon-closed {
  display: none;
}

.accordion .accordion-item.open .accordion-icon-open {
  display: block;
}

.accordion .accordion-item.open .accordion-content {
  display: flex;
}

.accordion .accordion-item .accordion-icon {
  cursor: pointer;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  display: flex;
}

@media (width >= 1200px) {
  .accordion .accordion-item .accordion-icon {
    margin-top: 3px;
  }
}

.accordion .accordion-item .accordion-body {
  flex-direction: column;
  flex-grow: 1;
  margin-top: 2px;
  margin-left: 1rem;
  display: flex;
}

@media (width >= 960px) {
  .accordion .accordion-item .accordion-body {
    margin-top: 0;
  }
}

.accordion .accordion-item .accordion-header {
  cursor: pointer;
  transition: color .3s;
  transition-delay: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.accordion .accordion-item .accordion-header:hover {
  color: var(--white);
}

.accordion .accordion-item .accordion-header h4 {
  vertical-align: top;
  margin: 0;
}

.accordion .accordion-item .accordion-header h4::selection {
  color: var(--text-primary);
}

.accordion .accordion-item .accordion-header .accordion-number {
  vertical-align: top;
  font-weight: bold;
  line-height: 120%;
  display: inline;
}

.accordion .accordion-item .accordion-header .accordion-number::selection {
  color: var(--text-primary);
}

@media (width >= 960px) {
  .accordion .accordion-item .accordion-header .accordion-number {
    line-height: 150%;
  }
}

.accordion .accordion-item .accordion-content {
  color: var(--navigation-active);
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
  display: none;
}

@container (width >= 600px) {
  .accordion .accordion-item .accordion-content {
    flex-direction: row;
  }
}

.accordion .accordion-item .accordion-content .accordion-text {
  flex-direction: column;
  width: 100%;
  display: flex;
}

.accordion .accordion-item .accordion-content p, .accordion .accordion-item .accordion-content h1, .accordion .accordion-item .accordion-content h2, .accordion .accordion-item .accordion-content h3, .accordion .accordion-item .accordion-content h4, .accordion .accordion-item .accordion-content h5, .accordion .accordion-item .accordion-content h6, .accordion .accordion-item .accordion-content ul, .accordion .accordion-item .accordion-content ol {
  margin-bottom: 1rem;
  display: block;
  position: relative;
  top: auto;
}

.accordion .accordion-item .accordion-content p:last-child, .accordion .accordion-item .accordion-content h1:last-child, .accordion .accordion-item .accordion-content h2:last-child, .accordion .accordion-item .accordion-content h3:last-child, .accordion .accordion-item .accordion-content h4:last-child, .accordion .accordion-item .accordion-content h5:last-child, .accordion .accordion-item .accordion-content h6:last-child, .accordion .accordion-item .accordion-content ul:last-child, .accordion .accordion-item .accordion-content ol:last-child {
  margin-bottom: 0;
}

.accordion .accordion-item .accordion-content .accordion-image {
  flex-shrink: 0;
  width: 100%;
  max-width: 320px;
}

@container (width >= 600px) {
  .accordion .accordion-item .accordion-content .accordion-image {
    width: 240px;
  }
}

@container (width >= 660px) {
  .accordion .accordion-item .accordion-content .accordion-image {
    max-width: auto;
    width: 320px;
  }
}

.accordion .accordion-item .accordion-content .accordion-image--left {
  order: -1;
}

.singlestep, .singlestep-content {
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.singlestep-header {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.singlestep-number {
  white-space: nowrap;
  line-height: 1;
}

.singlestep-line {
  background-color: var(--text-primary);
  width: 100%;
  height: 1px;
  display: block;
}

.steps {
  flex-wrap: wrap;
  justify-content: stretch;
  gap: 2rem;
  width: 100%;
  display: flex;
}

.steps .singlestep {
  flex-grow: 1;
  width: 288px;
}

@container (width >= 640px) {
  .steps .singlestep {
    width: 45%;
  }
}

@container (width >= 1200px) {
  .steps .singlestep {
    width: 188px;
  }
}

.jobs {
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  display: flex;
}

.jobs .job {
  border: 1px solid var(--text-disabled);
  border-radius: var(--general-radius);
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  display: flex;
}

.jobs .job .button {
  width: 100%;
}

.jobs .job-content {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.jobs .job-header {
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  display: flex;
}

.jobs .job-header svg {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
}

.jobs .job-header p {
  white-space: nowrap;
}

@container (width >= 649px) {
  .jobs .job {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
  }

  .jobs .job .button {
    width: auto;
  }

  .jobs .job .job-header {
    flex-wrap: nowrap;
  }
}

.teaser {
  --radius: 20px;
  --transition-transform: transform .5s cubic-bezier(.25, 1, .5, 1);
  width: 100%;
  display: block;
  position: relative;
  overflow: clip;
}

.teaser .notch--expandable {
  transition: var(--transition-transform);
  transform: translate3d(0, 100%, 0);
}

.teaser .button-icon-only {
  opacity: 0;
  transition: var(--transition-transform), opacity .3s, right .3s;
  transition-delay: 0;
  will-change: right, transform, opacity;
  position: absolute;
  right: -38px;
  transform: scale(.5)translate3d(0, 0, 0);
}

.teaser .expanding-button-spacer {
  will-change: width;
  width: 0;
  transition: width .3s;
}

@media (hover: hover) and (pointer: fine) {
  .teaser:hover .teaser-fg {
    transform: scale(1.05)translate3d(0, -2.5%, 0);
  }

  .teaser:hover .teaser-bg {
    transform: scale(1.05)translate3d(0, 2.5%, 0);
  }

  .teaser:hover .notch--expandable {
    transform: translate3d(0, 0, 0);
  }

  .teaser:hover .button-icon-only {
    opacity: 1;
    right: 10px;
    transform: scale(1)translate3d(0, 0, 0);
  }

  .teaser:hover .expanding-button-spacer {
    width: 48px;
  }
}

@media (hover: none) and (pointer: coarse) {
  .teaser .teaser-fg {
    transform: scale(1.05)translate3d(0, 5%, 0);
  }

  .teaser .teaser-bg {
    transform: scale(1.05)translate3d(0, -2.5%, 0);
  }

  .teaser .notch--expandable {
    transform: translate3d(0, 0, 0);
  }
}

.teaser-visual {
  aspect-ratio: 3 / 2;
  border-radius: var(--radius);
  background: #fff;
  width: 100%;
  display: block;
  position: relative;
  overflow: clip;
}

.teaser-visual img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.teaser-visual .teaser-bg {
  transition: var(--transition-transform);
  will-change: transform;
  transform: scale(1.06)translate3d(0, -2.5%, 0);
}

.teaser-visual .teaser-fg {
  transition: var(--transition-transform);
  will-change: transform;
  position: absolute;
  inset: 0;
  transform: scale(1.05)translate3d(0, 2.5%, 0);
}

.work-grid, .work-grid-static {
  gap: var(--gutter);
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  margin-top: 1rem;
  display: grid;
}

@media (width >= 720px) {
  .work-grid, .work-grid-static {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width >= 1200px) {
  .work-grid, .work-grid-static {
    grid-template-columns: repeat(3, 1fr);
  }
}

.work-grid-load-all {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
  display: none;
}

.work-grid-load-all.visible {
  display: flex;
}

.work-grid-info {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.writer {
  width: 100%;
}

.writer h1, .writer h2, .writer h3 {
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.writer h4 {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.writer p {
  margin-bottom: 1rem;
}

.writer ul {
  margin: 1rem 0;
}

.writer li p {
  margin: 0;
}

.people {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
  display: grid;
}

@container (width >= 480px) {
  .people {
    gap: var(--gutter);
    grid-template-columns: repeat(2, 1fr);
  }
}

@container (width >= 660px) {
  .people {
    grid-template-columns: repeat(3, 1fr);
  }
}

@container (width >= 880px) {
  .people {
    grid-template-columns: repeat(4, 1fr);
  }
}

@container (width >= 1200px) {
  .people {
    grid-template-columns: repeat(5, 1fr);
  }
}

.people-item {
  --radius: 10px;
  --transition-transform: transform .5s cubic-bezier(.25, 1, .5, 1);
  flex-direction: column;
  gap: .5rem;
  width: 100%;
  display: flex;
  position: relative;
}

.people-item-wrapper {
  position: relative;
}

.people-item-visual {
  aspect-ratio: 3 / 4;
  border-radius: var(--radius);
  background: #fff;
  width: 100%;
  display: block;
  position: relative;
  overflow: clip;
}

.people-item-visual img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.people-cta {
  flex-direction: column;
  grid-column: span 2;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  display: flex;
}

@container (width >= 440px) {
  .people-cta {
    grid-column: span 1;
  }
}

.people-cta-text {
  flex-direction: column;
  gap: 1rem;
  display: flex;
}

.verticalspacer {
  width: 100%;
}

.verticalspacer-extrasmall {
  height: 4rem;
}

.verticalspacer-small {
  height: 8rem;
}

.verticalspacer-large {
  height: 16rem;
}

.verticalspacer.hide-on-mobile {
  display: none;
}

@media (width >= 720px) {
  .verticalspacer.hide-on-mobile {
    display: block;
  }
}

.matomooptout, #matomo-opt-out {
  border-radius: var(--general-radius);
  background-color: #fff;
  width: 100%;
  padding: 1rem;
}

.matomooptout input, #matomo-opt-out input {
  margin: 3px 6px 3px 4px;
  appearance: auto !important;
}

.captcha {
  gap: 1rem;
  width: 100%;
  display: flex;
  position: relative;
}

.captcha img {
  border-radius: 4px;
  width: auto;
  min-height: 32px;
  max-height: 38px;
}

.captcha label {
  display: none !important;
}

.captcha input {
  min-width: 120px;
}

.captcha .alert.error {
  margin-left: 105px;
}

html {
  scroll-behavior: auto;
  font-size: 16px;
}

body {
  color: var(--text-primary);
  background-color: var(--surface-primary);
  font-family: var(--bw-gradual);
  padding: 0;
  font-size: .8rem;
  line-height: 1.5;
  transition: all .5s;
}

@media (width >= 720px) {
  body {
    font-size: 1rem;
  }
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: #0000;
}

::selection {
  color: #fff;
  background: var(--surface-secondary);
}

img {
  width: 100%;
}

svg {
  max-width: 100%;
}

.resize-helper {
  pointer-events: none;
  z-index: -1;
  height: 100lvh;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

a, a:visited, a:hover, a:active {
  color: inherit;
}

h1, h2, h3, h4 {
  font-family: var(--bw-gradual);
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  line-height: 100%;
}

h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

h3 {
  font-size: var(--h3);
}

h4 {
  font-size: var(--h4);
}

.teaser-text {
  font-size: var(--teaser-text);
  font-weight: bold;
  font-family: var(--bw-gradual);
  margin: 0;
  padding: 0;
  line-height: 125%;
}

.copy-large, .largetext, .large-text {
  font-size: var(--copy-large);
  font-family: var(--new-spirit);
  letter-spacing: -0.5%;
  margin: 0;
  padding: 0;
  line-height: 115%;
}

p, .copy-text {
  font-size: var(--copy-text);
  margin: 0;
  padding: 0;
  line-height: 145%;
}

p a, .copy-text a {
  text-underline-offset: 3px;
  text-decoration: underline;
}

strong {
  font-weight: bold;
}

@media (width >= 720px) {
  .remove-height-on-desktop {
    height: 0;
  }
}

.container {
  --gutter: 2rem;
  z-index: 10;
  display: block;
  position: relative;
}

.container.template-project {
  --gutter: 1rem;
}

main {
  padding-top: var(--general-padding-top);
  padding-bottom: var(--general-padding-top);
  padding-left: var(--general-padding-horizontal);
  padding-right: var(--general-padding-horizontal);
  background: var(--surface-primary);
  z-index: 100;
  border-bottom-right-radius: 45px;
  border-bottom-left-radius: 45px;
  min-height: 100svh;
  display: block;
  position: relative;
  overflow: clip;
}

.footer-spacer {
  height: calc(100svh - var(--general-padding-top));
  pointer-events: none;
  width: 100%;
}

.layout {
  gap: var(--gutter);
  max-width: var(--general-maxwidth);
  font-size: var(--copy-text);
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.grid {
  gap: var(--gutter);
  flex-direction: column;
  margin-bottom: 2rem;
  display: flex;
  position: relative;
}

.grid.reverseonmobile {
  flex-direction: column-reverse;
}

.grid.no-container .column {
  container: unset !important;
}

.grid.indent {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 1200px) {
  .grid.indent {
    width: 80%;
  }
}

@media (width >= 720px) {
  .grid {
    grid-gap: var(--gutter);
    grid-template-columns: repeat(12, 1fr);
    display: grid;
  }
}

.grid .column-2 {
  grid-column: span 4;
}

@media (width >= 1440px) {
  .grid .column-2 {
    grid-column: span 2;
  }
}

.column {
  --columns: 12;
  grid-column: span var(--columns);
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  display: flex;
  position: relative;
  container-type: inline-size;
}

.column.empty-column {
  container: unset;
  display: none;
}

@media (width >= 720px) {
  .column.empty-column {
    display: block;
  }
}

.column .splitcolumn {
  margin-top: calc(var(--gutter)  - 1rem);
  gap: var(--gutter);
  width: 100%;
  height: 100%;
  display: flex;
}

.column .splitcolumn-wrapper {
  flex-direction: column;
  flex: 1;
  width: 100%;
  display: flex;
}

.column .splitcolumn .single-image.stretch, .column .splitcolumn .video-autoplay-wrapper.stretch {
  height: 100%;
  min-height: 100px;
  display: block;
  position: relative;
}

.column .splitcolumn .single-image.stretch img, .column .splitcolumn .video-autoplay-wrapper.stretch img {
  object-fit: cover;
  height: 100%;
  position: absolute;
  inset: 0;
}

.sticky, .sticky-images figure, .sticky-images .image-w-button {
  top: var(--general-padding-top);
  position: sticky;
}

.sticky-images .image-w-button figure {
  position: relative;
  top: auto;
}

.sticky-text h1, .sticky-text h2, .sticky-text h3, .sticky-text h4, .sticky-text h5, .sticky-text p, .sticky-text .largetext, .sticky-text .large-text {
  top: var(--general-padding-top);
  position: sticky;
}

.wrap > .column {
  flex-flow: wrap;
}

.no-margin {
  margin: 0;
}
/*# sourceMappingURL=app.css.map */
