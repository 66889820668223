.verticalspacer {
    width: 100%;

    &-extrasmall {
        height: 4rem;
    }

    &-small {
        height: 8rem;
    }

    &-large {
    
        height: 16rem;
    }

    &.hide-on-mobile {
        display: none;

        @include respond-to('medium') {
            display: block;
        }
    }
}
