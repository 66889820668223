@import "./plyr.scss";

.video-autoplay-wrapper {
  position: relative;
  display: block;
  width: 100%;
  
  &.stretch {
    @include respond-to('medium') {
      height: 100%;

      .video-autoplay {
        min-height: 100px;
        display: block;
        height: 100%;
        position: absolute;
        inset: 0;
        object-fit: cover;
      }
    }
  }
}

.video-autoplay {
  width: 100%;
  display: block;
  position: relative;
  // border: 1px solid blue;
  border-radius: var(--general-radius);
}

.custom-plyr-wrapper {
  width: 100%;
}

.plyr {
  width: 100%;
  --plyr-color-main: var(--text-primary);
  border-radius: 20px;
  aspect-ratio: var(--aspect-ratio);
}


.plyr .plyr__control--overlaid svg {
  color: black;
}

.plyr.plyr--stopped video {
  opacity: 0;
}

.plyr--fullscreen-fallback { //must not have a css container as parent.
  width: 100vw;
  min-height: 100svh;
  max-height: 100lvh;
}