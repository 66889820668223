.footer-spacer {
  // max-height: 980px;
  
  @include respond-to('medium') {
    max-height: 980px;
  }
}

.footer {
  --surface-primary: var(--white);
  --text-primary: var(--black);
  --text-hover: var(--orange);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  // height: 100svh;
  top: auto;
  bottom: 0;
  left: 0;
  z-index: 0;
  color: var(--black);
  padding: 16px;
  padding-top: 2rem;
  padding-left: var(--general-padding-horizontal);
  padding-right: var(--general-padding-horizontal);
  text-align: center;
  background-color: var(--white);

  &:before {
    content: " ";
    display: block;
    position: absolute;
    pointer-events: none;
    height: 600px;
    // border: 1px solid red;
    background-color: var(--white);
    transform: translateY(-99%); //99 to prevent glitches
    left: 0;
    top:0;
    right: 0;
  }

  @include respond-to(small) {
    text-align: left;
  }

  &-container {
    width: 100%;
    max-width: var(--general-maxwidth);
    margin-left: auto;
    margin-right: auto;
  }

  &-cta {
    flex-flow: row;
    gap: 1rem;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 2rem;
    
    @include respond-to(small) {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-bottom: 4rem;
    }

    p {
      @include respond-to(small) {
        margin: 1rem 0;
      }
    }
  }

  &-columns {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    @include respond-to(small) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 4rem;

    }

    .footer-column1 {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .footer-column2 {
      text-align: center;
      
      // @include respond-to(small) {
      //   text-align: left;
      // }
    }

    .footer-column3 {
      display: flex;
      flex-direction: column;
      gap: 12px;
      align-items: center;
      
      @include respond-to(small) {
        align-items: flex-end;
      }
    }

    .footer-socials {
      display: flex;
      gap: 12px;
    }
  }


  .footer-links {
    display: flex;
    gap: 16px;

    a {
      text-decoration: underline;
      text-underline-offset: 2px;
      white-space: nowrap;
    }
  }

  .logo-wall {
    margin: 1rem 0;

    &.logowall-one-row {
      gap: 2rem;
      @include respond-to(medium) {
        gap: 3rem;
      }
      .logo-item {
        width: auto;
        height: 40px;

        @include respond-to(medium) {
          height: 60px;
        }


         img {
          height: 100%;
          width: auto;
         }
        // max-width: 200px;
        
        // @include respond-to(small) {
        //   width: 180px;
        //   max-width: 180px;
        // }
      }
    }
  }

}
