.button {
  border-radius: 100px;
  font-family: var(--new-spirit);
  font-size: var(--button-large);
  color: var(--surface-primary);
  background-color: var(--text-primary);
  position: relative;
  display: inline-flex;
  padding: 12px 20px; //previously 8px 20px
  justify-content: center;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
  cursor: pointer;
  max-width: 100%;
  transition: background-color 200ms, color 200ms, border 200ms;


  &.button-icon-only {
    padding: 12px;
  }

  .button-text {
    line-height: 100%;
    padding: 3px 0;
    vertical-align: middle;
    display: inline-block;
  }

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    stroke-width: 1.5;
  }

  &:visited {
    color: var(--surface-primary);
  }

  &:hover {
    background-color: var(--text-hover);
  }

  &.secondary {
    background-color: transparent;
    color: var(--text-primary);
    border: 1px solid var(--text-primary);

    &:hover {
      background-color: transparent;
      color: var(--text-hover);
      border: 1px solid var(--text-hover);
    }
  }

  &-icon-left {
    flex-direction: row-reverse;
  }

  &-size-small { //these styles are duplicated to in image-w-button.scss, please keep up to date
    padding: 10px 16px; //previously 7px 16px
    font-size: var(--button-small);

    &.button-icon-only {
      padding: 10px;
    }

    .button-text {
      padding: 1px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    svg {
      width: 16px;
      height: 16px;
      min-width: 16px;
      stroke-width: 1.5;
    }
  }
}
