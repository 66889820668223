.card {
  padding: 2rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
  border-radius: 30px;
  background: var(--surface-secondary);
  
  @container (min-width: 640px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    // p, .largetext {
    //   max-width: 480px;
    // }
  }
  
}