.captcha {
  display: flex;
  // flex-wrap: wrap;
  position: relative;
  gap: 1rem;
  width: 100%;

  img {
    border-radius: 4px;
    min-height: 32px;
    max-height: 38px;
    width: auto;
  }

  label {
    display: none !important;
  }

  input {
    min-width: 120px;
  }

  .alert.error {
    margin-left: 105px;
  }
    
}
  